<template>
  <div class="drop" @click="closeDrop">
    <div class="close" @click.stop.prevent="closeDrop">
      <img src="@/assets/mint/close.png" alt="" />
    </div>
    <div class="name">{{ imgList[nowIndex] && imgList[nowIndex].name }}</div>
    <div class="mainbox" @click.stop.prevent="" v-if="nftImgList">
      <div class="arrow" @click.stop.prevent="handlechange('reduce')">
        <img src="@/assets/mint/left.png" alt="" />
      </div>
      <div class="mid">
        <div class="mid-img">
          <img
            v-for="(item, index) in imgList"
            :class="{
              show: index === nowIndex,
            }"
            class="hidden"
            v-lazy="item.image"
            :key="`img_${index}`"
            alt=""
          />
        </div>
      </div>
      <div class="arrow" @click.stop.prevent="handlechange('add')">
        <img src="@/assets/mint/right.png" alt="" />
      </div>
    </div>
    <div class="total">{{ nowIndex + 1 }}/{{ nftImgList.length }}</div>
  </div>
</template>
<script>
import { defineComponent, onMounted, reactive, toRefs } from "vue";
export default defineComponent({
  props: {
    nftImgList: {
      type: [String, Array, Object],
      default: "",
    },
    imgIndex: {
      type: [Number],
      default: 0,
    },
  },
  setup(props, { emit }) {
    const data = reactive({
      nowIndex: 0,
      showBefore: false,
      showAfter: false,
      imgList: [],
      allImgList: [],
    });
    const closeDrop = () => {
      // alert("close");
      emit("close");
    };

    const handlechange = (type) => {
      if (type === "add" && data.nowIndex + 1 < props.nftImgList.length) {
        if (data.imgList.length === data.nowIndex + 1) {
          data.imgList.push(data.allImgList[data.nowIndex + 1]);
        }
        data.nowIndex++;
      } else if (type === "reduce" && data.nowIndex > 0) {
        data.nowIndex--;
      }
    };
    onMounted(() => {
      data.allImgList = JSON.parse(JSON.stringify(props.nftImgList));
      data.nowIndex = props.imgIndex * 1;
      for (let i = 0, iLen = data.nowIndex + 1; i < iLen; i++) {
        data.imgList.push(data.allImgList[i]);
      }
    });
    return {
      ...toRefs(data),
      closeDrop,
      handlechange,
    };
  },
});
</script>
<style scoped lang="scss">
.drop {
  .close {
    top: 19rem;
    right: 4rem;
    text-align: right;
    position: absolute;
    cursor: pointer;
    img {
      width: 4rem;
      height: 4rem;
      // display: block;
    }
  }
}
.name {
  font-size: 2rem;
  color: #fff;
  transform: translateY(11.5vh);
  font-family: "RB";
}
.mainbox {
  margin-top: 13vh;
  // left: 50%;
  // transform: translate(-50%, -50%);
  display: flex;
  // z-index: 2033;
  width: 100vw;
  justify-content: center;
  align-items: center;
  .arrow {
    cursor: pointer;
    width: 3rem;
    height: 7.5rem;
    position: relative;
    z-index: 10;
    img {
      width: 100%;
      height: 100%;
      display: block;
    }
  }
}
.mid {
  position: relative;
  width: auto;
  img {
    position: absolute;
    height: 75vh;
    width: 75vh;
    margin: 0 18rem;
    transition: all 0.3s;
    opacity: 0;
    z-index: 1;
    left: 0;
    object-fit: cover;
    &.hidden {
      opacity: 0;
      transition: all 0.3s;
      &.show {
        z-index: 3;
        opacity: 1;
        position: relative;
      }
    }
  }
}
.total {
  color: #fff;
  font-size: 2rem;
  margin-top: 3.9rem;
}
@media only screen and (max-width: 765px) {
  .drop {
    height: 100vh !important;
    .close {
      top: 2rem;
      right: 2rem;
      text-align: right;
      position: absolute;
      cursor: pointer;
      img {
        width: 2.2rem;
        height: 2.2rem;
        // display: block;
      }
    }
  }
  .mainbox {
    margin-top: calc((100vh - 90vw) / 2);
    // left: 50%;
    // transform: translate(-50%, -50%);
    display: flex;
    // z-index: 2033;
    width: 100vw;
    justify-content: center;
    align-items: center;
    .arrow {
      cursor: pointer;
      width: 3rem;
      height: 3rem;
      img {
        width: 1rem;
        height: 2.5rem;
        display: inline-block;
      }
    }
  }
  .name {
    font-size: 1.5rem;
    font-family: "RB";
    color: #fff;
    transform: translateY(calc((100vh - 96vw) / 2));
  }
  .mid {
    position: relative;
    width: auto;
    img {
      position: absolute;
      height: 65vw;
      width: 65vw;
      object-fit: cover;
      margin: 0 2.5rem;
      transition: all 0.3s;
      opacity: 0;
      z-index: 1;
      left: 0;
      &.hidden {
        opacity: 0;
        transition: all 0.3s;
        &.show {
          z-index: 3;
          opacity: 1;
          position: relative;
        }
      }
    }
  }
  .total {
    color: #fff;
    font-size: 1rem;
    margin-top: 1.5rem;
  }
}
</style>
