<template>
  <div class="mybox">
    <div class="filter" @click="showFilter = true">
      FILTER <img src="@/assets/gallery/filter.png" alt="" />
    </div>
    <div class="leftNav">
      <ul>
        <li
          v-for="item in nameList"
          :key="'name_' + item"
          @click="changeNav(item)"
          :class="{ bindCheck: navIndex === item }"
        >
          {{ item }}
        </li>
      </ul>
    </div>
    <div class="rightBox">
      <ul class="showani" v-show="rightList.length > 0">
        <li v-for="item in rightList" :key="item.tips" @click="showInfo(item)">
          <img :src="item.imgUrl" alt="" />
        </li>
        <li><i></i></li>
        <li><i></i></li>

        <!-- <i></i> -->
        <!-- <li><i></i></li> -->
      </ul>
    </div>
  </div>
  <div class="navList" :class="{ showNav: showFilter }">
    <div class="close" @click="showFilter = false"></div>
    <ul class="nav">
      <li
        v-for="item in mobileNameList"
        :key="item"
        @click="changeNav(item)"
        :class="{ bindCheck: navIndex === item }"
      >
        {{ item.toUpperCase() }}
      </li>
    </ul>
  </div>
  <div
    class="showCard showani"
    v-show="cardShow"
    :class="{ bindhide: hideani }"
  >
    <div class="mobileDrop" @click="clearInfo"></div>
    <div class="card">
      <div class="leftImg">
        <img :src="cardInfo.imgUrl" alt="" />
      </div>
      <div class="rightInfo">
        <div class="close" @click="clearInfo">
          <img src="@/assets/mint/close_p.png" alt="" />
        </div>
        <h1 class="species">{{ cardInfo.species }}</h1>
        <h2 class="tips">
          <span>AKA </span><span class="light_p">{{ cardInfo.tips }}</span>
        </h2>
        <p class="info">{{ cardInfo.info }}</p>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, onMounted, reactive, toRefs } from "vue";
export default defineComponent({
  setup() {
    const data = reactive({
      navIndex: "",
      rightList: [],
      nameList: [
        "SPECIES",
        "Momus",
        "Eucleia",
        "Aether",
        "Empusa",
        "Algos",
        // "ƒè¾“BE…¥ç",
      ],
      mobileNameList: [
        "Momus",
        "Eucleia",
        "Aether",
        "Empusa",
        "Algos",
        // "ƒè¾“BE…¥ç",
      ],
      speciesList: {
        SPECIES: [
          {
            species: "MOMUS",
            tips: "CIRCLE OF LIFE",
            info: "Ordinary, yet imperishable.",
            imgUrl: require("@/assets/gallery/1.png"),
          },
          {
            species: "EUCLEIA",
            tips: "AWAKENED ORCS",
            info: "Behold, as the metaverse shall embrace resurgence upon our awakening.",
            imgUrl: require("@/assets/gallery/2.png"),
          },
          {
            species: "AETHER",
            tips: "GUARDIAN OF LIGHT",
            info: "As impenetrable as our skin we stand firm and vow to fulfil our righteous duty. ",
            imgUrl: require("@/assets/gallery/3.png"),
          },
          {
            species: "EMPUSA",
            tips: "HEART REAPER",
            info: "Though we may be relentlessly depleted, our souls still yearn to fight.",
            imgUrl: require("@/assets/gallery/4.png"),
          },
          {
            species: "ALGOS",
            tips: "MASK OF PAIN",
            info: "Summer is here, Rotting and fetid seeds are sprouting",
            imgUrl: require("@/assets/gallery/5.png"),
          },
          // {
          //   species: "ƒè¾“BE…¥ç",
          //   tips: "æ¯”èTO",
          //   info: "æ¯”èTOƒè¾“BE…¥çš„éduªŒè¯ CONTINUED.ç .å",
          //   imgUrl: require("@/assets/gallery/6.png"),
          // },
        ],
        Momus: [
          {
            species: "MOMUS",
            tips: "CIRCLE OF LIFE",
            info: "We are ordinary like grass but still Growing Strong.",
            imgUrl: require("@/assets/gallery/1.png"),
          },
        ],
        Eucleia: [
          {
            species: "EUCLEIA",
            tips: "AWAKENED ORCS",
            info: "Once we are awakened, we will shake up the entire metaverse. ",
            imgUrl: require("@/assets/gallery/2.png"),
          },
        ],
        Aether: [
          {
            species: "AETHER",
            tips: "GUARDIAN OF LIGHT",
            info: "The diamond armor gives us a more significant purpose, never break.",
            imgUrl: require("@/assets/gallery/3.png"),
          },
        ],
        Empusa: [
          {
            species: "EMPUSA",
            tips: "HEART REAPER",
            info: "Even when we’re decapitated our souls are still fighting",
            imgUrl: require("@/assets/gallery/4.png"),
          },
        ],
        Algos: [
          {
            species: "ALGOS",
            tips: "MASK OF PAIN",
            info: "Summer is here, Rotting and fetid seeds are sprouting",
            imgUrl: require("@/assets/gallery/5.png"),
          },
        ],
        // "ƒè¾“BE…¥ç": [
        //   {
        //     species: "ƒè¾“BE…¥ç",
        //     tips: "æ¯”èTO",
        //     info: "æ¯”èTOƒè¾“BE…¥çš„éduªŒè¯ CONTINUED.ç .å",
        //     imgUrl: require("@/assets/gallery/6.png"),
        //   },
        // ],
      },
      cardShow: false,
      hideani: false,
      cardInfo: "",
      showFilter: false,
    });
    onMounted(() => {
      changeNav("SPECIES");
    });
    const changeNav = (index) => {
      data.rightList = [];
      data.showFilter = false;
      setTimeout(() => {
        data.navIndex = index;
        const lists = data.speciesList[data.navIndex];
        data.rightList = lists;
      }, 100);
    };
    const showInfo = (info) => {
      data.showFilter = false;
      data.cardShow = true;
      data.cardInfo = info;
      data.hideani = false;
    };
    const clearInfo = () => {
      data.hideani = true;

      setTimeout(() => {
        data.cardShow = false;
        data.cardInfo = "";
      }, 200);
    };
    return {
      ...toRefs(data),
      changeNav,
      showInfo,
      clearInfo,
    };
  },
});
</script>
<style scoped lang="scss">
@import "@/common/css/common.scss";

@keyframes page-show {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
@keyframes page-hide {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
.mybox {
  display: flex;
  height: calc(100vh - 15.7rem);
  overflow: hidden auto;
  position: relative;
  padding-left: 13.5rem;
  // justify-content: center;
  // z-index: 2000;
  .filter {
    display: none;
  }
  .leftNav {
    padding-top: 7.8rem;

    margin-right: 6vw;
    // margin-left: 13.8rem;
    ul {
      text-align: left;
      li {
        color: $main;
        font-size: 2.9rem;
        font-family: "RB";
        cursor: pointer;
        margin-bottom: 0.4rem;
        &:not(:nth-child(1), :last-child) {
          border-bottom: 0.2rem solid $main;
          width: 10.8rem;
          padding-bottom: 0.7rem;
          &:hover,
          &.bindCheck {
            color: rgba(0, 0, 0, 0.25);
            // color: $sub;
            // -webkit-text-stroke: 0.15rem $main;
            // text-stroke: 0.15rem $main;
          }
        }
        &:not(:nth-child(1)) {
          &:hover,
          &.bindCheck {
            color: rgba(0, 0, 0, 0.2);
            // color: $sub;
            // -webkit-text-stroke: 0.15rem $main;
            // text-stroke: 0.15rem $main;
          }
        }
        &:nth-child(1) {
          font-size: 4rem;
          color: $title;
          margin-bottom: 6.3rem;
        }
      }
    }
  }
  .rightBox {
    height: 100%;
    overflow-y: auto;
    // width: 62vw;
    padding-top: 7.8rem;
    &::-webkit-scrollbar {
      display: none;
    }
    ul {
      display: flex;
      flex-flow: wrap;
      i {
        width: 42.8rem;
      }
      li {
        width: 42.8rem;
        height: 42.8rem;
        border-radius: 2rem;
        overflow: hidden;
        margin-bottom: 2.3rem;
        box-shadow: 0.5rem 0.5rem 1rem 0px #4a4e6964;
        transform: rotate(0deg);
        img {
          width: 100%;
          height: auto;
          display: block;
          transition: all 0.3s;
        }
        &:not(:nth-child(3n)) {
          margin-right: 2.9rem;
        }
        &:hover {
          cursor: pointer;
          img {
            // transform: scale(1.1);
            transform: scale(1.05) translate(-1%, -2%);
            transition: all 0.3s;
          }
        }
        &:last-child {
          box-shadow: none;
          height: 0;
        }
        &:nth-last-child(2) {
          box-shadow: none;
          height: 0;
        }
      }
    }
  }
}
.navList {
  display: none;
}
.showani {
  animation: page-show linear 0.3s;
}
.showCard {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 2001;
  top: 0;
  background: rgba(0, 0, 0, 0.65);
  &.bindhide {
    animation: page-hide linear 0.3s;
  }
  .card {
    z-index: 2002;
    position: absolute;
    background: $title;
    padding: 1.1rem 3.6rem 1.3rem 1.2rem;
    text-align: left;
    display: flex;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 2.5rem;
    .leftImg {
      width: 52.6rem;
      height: 52.6rem;
      border-radius: 2rem;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        display: block;
      }
    }
    .rightInfo {
      display: inline-flex;
      flex-flow: column;
      margin-left: 6.1rem;
      flex: 1;
      overflow: hidden;
      padding-right: 2rem;
      min-width: 45rem;
      .close {
        width: 4rem;
        height: 4rem;
        border-radius: 50%;
        background: $sub;
        position: relative;
        margin-top: 3.1rem;
        align-self: flex-end;
        right: -2rem;
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
          display: block;
        }
      }
      .species {
        color: $sub;
        font-size: 6.6rem;
        border-bottom: 0.3rem solid $sub;
        font-family: "RB";
        margin-top: 0.6rem;
      }
      .tips {
        margin-bottom: 8.3rem;
        margin-top: 1.9rem;
        white-space: nowrap;
        span {
          font-family: "RB";

          font-size: 3.6rem;
          &.light_p {
            color: $sub;
          }
        }
      }
      .info {
        font-size: 2.5rem;
        color: #818181;
      }
    }
  }
  // .mobileDrop {
  //   display: none;
  // }
}
@media only screen and (max-width: 756px) {
  .mybox {
    height: auto;
    padding-top: 10rem;
    display: block;
    padding-left: 0;
    .filter {
      display: flex;
      justify-content: flex-start;
      margin-left: 3rem;
      margin-bottom: 2rem;
      color: $title;
      font-family: "RB";
      font-size: 1.2rem;
      align-items: center;
      img {
        width: 1.2rem;
        height: 1.2rem;
        display: block;
        margin-left: 0.6rem;
      }
    }
    .leftNav {
      display: none;
    }
    .rightBox {
      height: 100%;
      overflow-y: auto;
      width: 100vw;
      padding-top: 0;
      ul {
        display: flex;
        flex-flow: wrap;
        justify-content: center;
        li {
          width: 16.5rem;
          height: 16.5rem;
          border-radius: 2rem;
          overflow: hidden;
          margin-bottom: 1.1rem;
          box-shadow: 0.5rem 0.5rem 1rem 0px #4a4e6964;
          img {
            transition: all 0.3s;
            width: 16.5rem;
            height: 16.5rem;
            height: auto;
            display: block;
            border-radius: 2rem;
          }
          &:not(:nth-child(3n)) {
            margin-right: 0;
          }
          &:not(:nth-child(2n)) {
            margin-right: 1rem;
          }
          &:last-child {
            opacity: 0;
          }
          &:hover {
            cursor: pointer;
            img {
              // transform: scale(1.1);
              transform: scale(1.05) translate(-1%, -2%);
              transition: all 0.3s;
            }
          }
        }
      }
    }
  }
  .navList {
    display: flex;
    flex-flow: column;
    position: fixed;
    top: 0;
    padding: 5rem 2.8rem 0;
    z-index: 2000;
    height: 100vh;
    width: 50vw;
    left: 0;
    transform: translateX(-50vw);
    transition: all 0.3s;
    overflow: hidden;
    &::after {
      content: "";
      position: absolute;
      width: 110%;
      height: 110%;
      background: rgba(255, 255, 255, 0.78);
      -webkit-backdrop-filter: blur(0.5rem);
      backdrop-filter: blur(0.5rem);
      filter: brightness(0.85);
      top: -5%;
      left: -5%;
      z-index: -1;
    }
    &.showNav {
      transform: translateX(0);
    }
    .close {
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 50%;
      background: $sub;
      position: relative;
      margin-top: 0.62rem;
      align-self: flex-start;
      cursor: pointer;
      &::before {
        content: "";
        position: absolute;
        width: 1.5rem;
        height: 0.24rem;
        background: $title;
        top: 1.2rem;
        left: 0.5rem;
      }
    }
    .nav {
      text-align: left;
      margin-top: 6.8rem;
      li {
        color: $sub;
        font-size: 2rem;
        margin-bottom: 2.4rem;
      }
    }
  }
  .showCard {
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 2001;
    top: 0;
    background: rgba(255, 255, 255, 0.69);
    &.bindhide {
      animation: page-hide linear 0.3s;
    }
    .mobileDrop {
      position: absolute;
      z-index: 2002;
      width: 100%;
      height: 100%;
    }
    .card {
      z-index: 2003;
      position: absolute;
      background: $title;
      padding: 1.2rem;
      text-align: left;
      display: flex;
      flex-flow: column;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 2.5rem;
      .leftImg {
        width: 23.9rem;
        height: 23.9rem;
        border-radius: 2rem;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          display: block;
        }
      }
      .rightInfo {
        display: inline-flex;
        flex-flow: column;
        margin-left: 0;
        padding: 0 0.7rem;
        flex: 1;
        overflow: hidden;
        min-width: unset;
        .close {
          width: 2.4rem;
          height: 0.4rem;
          border-radius: 2rem;
          background: $sub;
          position: absolute;
          align-self: flex-end;
          top: -5rem;
          right: 1.6rem;
          cursor: pointer;
          img {
            display: none;
          }
          &::before {
            content: "";
            display: none;
          }
        }
        .species {
          color: $sub;
          font-size: 2.8rem;
          border-bottom: 0.2rem solid $sub;
          font-family: "RB";
          margin-top: 1.8rem;
        }
        .tips {
          margin-bottom: 3.8rem;
          margin-top: 0.38rem;
          white-space: unset;
          span {
            font-family: "RB";
            font-size: 1.9rem;
            &.light_p {
              color: $sub;
            }
          }
        }
        .info {
          font-size: 1.3rem;
          line-height: 1.6rem;
          color: #818181;
          margin-bottom: 3.7rem;
        }
      }
    }
  }
}
</style>
