<template>
  <div class="home page-ani mobile">
    <div class="mid">
      <!-- <img alt="Vue logo" src="../assets/logo.png" /> -->
      <div class="left">
        <div class="mytitle">
          <picture>
            <source
              media="(max-width: 756px)"
              srcset="@/assets/home/mobile_title.png"
            />
            <img class="title" src="@/assets/home/title.png" alt="" />
          </picture>
          <!-- <img class="title_top" src="@/assets/home/title_top.png" alt="" /> -->
        </div>
        <!-- <div class="join">JOIN US</div> -->
        <ul class="linkList">
          <li>
            <a href="https://opensea.io/collection/destroyand" target="_blank">
              <img src="@/assets/link/opensea.png" alt="" />
            </a>
          </li>
          <li>
            <a href="https://twitter.com/destroyand_" target="_blank">
              <img src="@/assets/link/twitter.png" alt="" />
            </a>
          </li>
          <li>
            <a href="https://discord.com/invite/destroyand" target="_blank">
              <img src="@/assets/link/discord.png" alt="" />
            </a>
          </li>
          <li>
            <a href="https://www.instagram.com/destroyand_" target="_blank">
              <img src="@/assets/link/ins.png" alt="" />
            </a>
          </li>
        </ul>
      </div>
      <div class="rightGirl">
        <picture>
          <source
            media="(max-width: 756px)"
            srcset="@/assets/home/mobile_girl.png"
          />
          <img class="girl" src="@/assets/home/girl.png" alt="" />
        </picture>
        <img class="eye-bg" src="@/assets/home/eye_bg.png" alt="" />
        <img class="eye" src="@/assets/home/eye_left.png" alt="" />
        <img class="eye" src="@/assets/home/eye_right.png" alt="" />
        <picture>
          <source
            media="(max-width: 756px)"
            srcset="@/assets/mobile_gif/star.gif"
          />
          <img class="star" src="@/assets/gif/star.gif" alt="" />
        </picture>
      </div>
    </div>
    <router-link to="/mint" class="gomintbtn">
      <div>
        <img src="@/assets/home/mint_arrow.png" alt="" />
      </div>
    </router-link>
  </div>
</template>
<script>
import { defineComponent, onMounted, reactive, toRefs } from "vue";
export default defineComponent({
  setup() {
    const data = reactive({});
    const moveEye = () => {
      //声明脑袋的默认偏移
      const test = document.getElementsByClassName("rightGirl")[0];
      var offsetLeft = test.offsetLeft;
      var offsetTop = test.offsetTop;
      //声明左眼夹角a1、右眼夹角a2
      var a1, a2;
      //声明左眼圆心(X1,Y1)、右眼圆心(X2,Y2)
      var X1 = -10,
        Y1 = -5,
        X2 = -10,
        Y2 = -5;
      //声明半径
      var R = 5;
      const ball1 = document.getElementsByClassName("eye")[0];
      const ball2 = document.getElementsByClassName("eye")[1];

      document.onmousemove = function (e) {
        e = e || event;
        //获取鼠标坐标
        var x = e.clientX - 300;
        var y = e.clientY - 200;
        //更新夹角a1、a2
        a1 = Math.atan2(x - X1 - offsetLeft, y - Y1 - offsetTop);
        a2 = Math.atan2(x - X2 - offsetLeft, y - Y2 - offsetTop);
        //更新左眼、右眼的left、top值
        ball1.style.left = (R * Math.sin(a1) + X1 + 10) / 10 + "rem";
        ball1.style.top = (R * Math.cos(a1) + Y1 + 5) / 10 + "rem";
        ball2.style.left = (R * Math.sin(a2) + X2 + 10) / 10 + "rem";
        ball2.style.top = (R * Math.cos(a2) + Y2 + 5) / 10 + "rem";
      };
    };
    onMounted(() => {
      moveEye();
    });
    return {
      ...toRefs(data),
    };
  },
});
</script>

<style lang="scss" scoped>
@import "~@/common/css/common.scss";
@keyframes mint-scale {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.85);
  }
  100% {
    transform: scale(1);
  }
}
.home {
  width: 100%;
  height: calc(100vh - 15.6rem);
  min-height: 45vw;
  background: #000;
  // margin-top: -15.6rem;
  // padding-top: 15.6rem;
  overflow: auto hidden;
  &::-webkit-scrollbar {
    display: none;
  }
}
.mid {
  // margin-top: 35rem;
  position: absolute;
  left: 0;
  padding-top: 5rem;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-left: 15rem;
  .left {
    width: 83.3rem;
    height: auto;
    padding-top: 14rem;
    .mytitle {
      position: relative;
      z-index: 1;
      .title {
        width: 100%;
        height: auto;
        display: block;
      }
      .title_top {
        position: absolute;
        z-index: 2;
        width: 24rem;
        height: 24rem;
        display: block;
        top: 3.7rem;
        right: 5.4rem;
      }
    }
    .join {
      font-size: 2.4rem;
      margin: 2.3rem auto 4.5rem;
      border: 0.3rem solid $sub;
      color: $sub;
      width: 15rem;
      border-radius: 2rem;
      transform: translateX(-4.8rem);
      // font-family: "PF_bold";
      font-family: "RB";
      height: 4rem;
      line-height: 3.6rem;
    }
    .linkList {
      display: inline-flex;
      align-items: center;
      transform: translateX(-4.8rem);
      margin-top: 7.3rem;
      li {
        &:not(:last-child) {
          margin-right: 3.1rem;
        }
        img {
          height: auto;
          display: block;
          width: 4.4rem;
          height: 4.4rem;
        }
        &:hover {
          transform: unset;
          opacity: 0.7;
          background: unset;
        }
        &:active {
          transform: unset;
          opacity: 0.7;
        }
      }
    }
  }
  .rightGirl {
    position: relative;
    width: 93.9rem;
    height: 93.9rem;
    overflow: hidden;
    // margin-right: 4rem;
    margin-left: -3rem;

    img {
      width: 100%;
      height: auto;
      display: block;
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      &.girl {
        z-index: 3;
      }
      &.eye_bg {
        z-index: 1;
      }
      &.left_eye {
        width: 3rem;
        height: 2.8rem;
        left: 32rem;
        top: 34rem;
      }
      &.right_eye {
        width: 3.9rem;
        height: 3.5rem;
        left: 47rem;
        top: 34rem;
      }
      &.star {
        z-index: 4;
        width: 106.2rem;
        top: -12rem;
        left: -5.5rem;
      }
    }
  }
}
.gomintbtn {
  position: fixed;
  right: 0;
  top: 22.4rem;
  width: 21.5rem;
  height: 10.4rem;
  background: url("../assets/home/mint_btn.png");
  background-size: 100%;
  background-repeat: no-repeat;
  cursor: pointer;
  img {
    animation: mint-scale 2.5s infinite;
    width: 9.3rem;
    height: auto;
    display: block;
    margin-left: 10.7rem;
    margin-top: 1.3rem;
  }
}
@media only screen and (max-width: 765px) {
  .home {
    min-height: 100vh;
  }
  .mid {
    // margin-top: 35rem;
    position: absolute;
    left: 50%;
    margin-top: 0;
    top: 2vh;
    transform: translateX(-50%) translateY(0);
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: center;
    padding-top: 5rem;
    padding-left: 0.5rem;
    width: 100%;
    overflow: hidden;

    .left {
      width: 30.9rem;
      height: auto;
      // margin-right: 0;
      padding-top: 2.8rem;
      .title {
        width: 100%;
        height: auto;
        display: block;
        content: url("../assets/home/mobile_title.png");
      }

      .join {
        display: none;
        margin: 0;
      }
      .linkList {
        display: none;
        align-items: center;
      }
    }
    .title_top {
      display: none !important;
    }
    .rightGirl {
      position: relative;
      width: 45.9rem;
      height: 45.9rem;
      margin-top: -6rem;
      margin-left: 0;
      background: #000;
      img {
        width: 100%;
        height: 100%;
        display: block;
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        &.girl {
          z-index: 3;
          content: url("../assets/home/mobile_girl.png");
        }
        &.eye_bg,
        &.left_eye,
        &.right_eye {
          display: none;
        }
        &.star {
          z-index: 4;
          width: 100%;
          top: 0;
          left: 0;
          content: url("../assets/mobile_gif/star.gif");
        }
      }
    }
  }
  .gomintbtn {
    position: fixed;
    right: 0;
    top: 40%;
    width: 8.9rem;
    height: 4.4rem;
    background: url("../assets/home/mint_btn.png");
    background-size: 100%;
    background-repeat: no-repeat;
    cursor: pointer;
    transform: translateY(-100%);
    img {
      animation: mint-scale 2.5s infinite;
      width: 4rem;
      height: auto;
      display: block;
      margin-left: 4.5rem;
      margin-top: 0.6rem;
    }
  }
}
</style>
