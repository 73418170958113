<template>
  <div class="mint">
    <div class="nft">
      <picture>
        <source
          media="(max-width: 756px)"
          srcset="@/assets/imgs/mobile_bg.png"
        />
        <img src="@/assets/imgs/story.png" alt="" />
      </picture>
      <img class="ios" src="" alt="" />
      <img class="ios" src="" alt="" />
    </div>
    <p class="info">we will be coming soon……</p>
  </div>
</template>
<script>
import { defineComponent, reactive, toRefs } from "vue";
export default defineComponent({
  setup() {
    const data = reactive({});
    return {
      ...toRefs(data),
    };
  },
});
</script>
<style scoped lang="scss">
@keyframes show-des {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}
.ios {
  display: none;
}
.mint {
  position: relative;
  height: calc(100vh - 15.7rem);
  // background: 100%;
  background-size: 100%;
  overflow: auto;
  .nft {
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    left: 0;
    img {
      display: block;
      width: calc(100% + 3.6rem);
      // max-width: 45vw;
      margin-left: -3.6rem;
      height: auto;
      // margin: 0 auto;
    }
  }
  .info {
    position: absolute;
    font-size: 10rem;
    color: #000;
    animation: show-des 4s infinite;
    top: 40%;
    transform: translateY(-50%);
    width: 100%;
    z-index: 2;
    font-family: "DTL";
  }
}
@media only screen and (max-width: 756px) {
  .ios {
    display: block;
  }
  .mint {
    height: calc(100vh);

    background: #000;
    .info {
      top: 42%;
      color: #8f48ff;
      font-size: 2.7rem;
      font-family: "PF_bold";
    }
    .nft {
      top: 45%;
      img {
        width: 90vw;
        margin: 0 auto;
        max-width: unset;
        content: url("../assets/imgs/mobile_bg.png");
        &.ios {
          margin-top: 2rem;
        }
      }
    }
  }
}
</style>
