<template>
  <div class="story page-ani mobile">
    <div class="main">
      <h1 class="title1">
        OUR&ensp;STORY<img src="@/assets/story/star_black.png" alt="" />
      </h1>
      <p class="info">
        I stumbled across the documentary The First Wave by chance. This
        document details how the hospital staff and patients in one of the
        hospitals in Queens, New York dealt with virus threats during the first
        four months of the covid-19 pandemic. <br /><br />
        One of the protagonists is a female African-American doctor who actively
        sought racial equality at the time. Her spirit of never giving up in the
        face of difficulties deeply touched me - despite confronting an enormous
        threat, the urge to fight against pandemics and fight for equality
        remains strong. <br /><br />
        Since the start of our project, we have been thinking about how we can
        bring value to our users. It’s okay if a company can make a bunch of
        people rich, but I don’t believe that’s a big deal. I hope we are doing
        something worthwhile and meaningful in the long run. After watching this
        documentary, I think we have the answer. <br /><br />
        In our opinion, the spirit of the African-American female doctor can be
        captured as ‘destroy and rebuild’, that is, to stick to what you love
        and dare to push through obstacles. Starting from ‘Destroy and…’, we
        want to convey the same value and spirit to the world. <br /><br />
        In the present day, people from all around the globe suffer under the
        predicament of war, epidemic, inflation, et cetera. Many have flickering
        sparks in within, but are unable to ignite and set their hearts on fire.
        We inspire those who have passion and ambition to join us on this
        journey; to overcome, breakthrough; destroy and rebuild. <br /><br />
        Our culture is dedicated to those who yearn for evolution. There are a
        lot of areas that we’ve observed that have this spiritual core: Skate,
        Crypto, hip-pop, etc, Every hardship we encounter shall not hinder our
        progress, it is in fact a fuel, inspiring us to continue exploring and
        push ourselves towards innovation.In the future we will be organizing
        activities like Destroy and Skate, Destroy and Crypto, Destroy and
        Hiphop, Destroy and X to spread our culture and strengthen community
        consensus. <br /><br />
        This is “Destroy and…”, and we are a group of ordinary but cool guys
        just like you. We hope to build a community and pass on the spirit of
        ‘destroy and ‘rebuild’ together in the future. <br /><br />
        Founder of Destroy and… Egrill
      </p>
      <section class="bottomLink pc">
        <div class="link">
          <img class="left_link" src="@/assets/story/link_b.png" alt="" />
          <div class="show">
            <span>https://destroyand.com/</span>
            <img
              @click="copy('https://destroyand.com/')"
              class="copy"
              src="@/assets/story/copy_b.png"
              alt=""
            />
          </div>
        </div>
      </section>
      <picture>
        <source
          media="(max-width: 756px)"
          srcset="@/assets/mobile_gif/point.gif"
        />
        <img class="r-gif" src="@/assets/gif/point.gif" alt="" />
      </picture>
    </div>
    <Bottom />
  </div>
</template>
<script>
import { defineComponent, reactive, toRefs } from "vue";
import Bottom from "../components/Bottom.vue";
import useClipboard from "vue-clipboard3";
export default defineComponent({
  components: {
    Bottom,
  },
  setup() {
    const data = reactive({});
    const { toClipboard } = useClipboard();

    const copy = async (text) => {
      try {
        await toClipboard(text);
        console.log("Copied to clipboard");
      } catch (e) {
        console.error(e);
      }
    };
    return {
      ...toRefs(data),
      copy,
    };
  },
});
</script>
<style scoped lang="scss">
@import "@/common/css/common.scss";
.story {
  margin: 0 auto;
  // height: calc(100vh - 15.7rem);
  // width: 100%;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  .nft {
    position: absolute;
    z-index: 1;
    top: 45rem;
    width: 100%;
    left: 0;
    img {
      display: block;
      width: 150rem;

      height: auto;
      margin: 0 auto;
    }
  }
  .main {
    padding-top: 19rem;
    position: relative;
    padding-bottom: 20rem;
    min-height: calc(100vh - 25rem);
    .title1 {
      position: relative;
      img {
        margin-left: 4.6rem;
        top: -1.6rem;
      }
    }
    .r-gif {
      width: 30.4rem;
      height: auto;
      position: absolute;
      top: 6.6rem;
      right: 13.6rem;
    }
    .title1 {
      color: $info;
      text-align: right;
      margin-bottom: 14.8rem;
      letter-spacing: -0.6rem;
      font-family: "DTL";
      width: fit-content;
      padding-left: 18rem;
      img {
        width: 3.3rem;
        height: 3.3rem;
        position: absolute;
      }
      &::after {
        content: "";
        width: calc(100% + 1rem);
        height: 0.5rem;
        background: #000;
        position: absolute;
        right: -1rem;
        bottom: 1.5rem;
      }
    }
    .title2 {
      color: #ffffff;
      font-size: 3rem;
    }
    .info {
      font-size: 2.8rem;
      text-align: left;
      line-height: 4rem;
      padding-left: 19.5rem;
      padding-right: 32.8rem;
      .light {
        font-size: 2.8rem;
        line-height: 3rem;

        color: #fff;
      }
    }
  }
  .bottomLink {
    text-align: left;
    padding-left: 20rem;
    .link {
      display: inline-flex;
      align-items: center;
      border-radius: 50%;
      margin-top: 9.7rem;
      // border: 0.3rem solid #000;
      &:hover {
        .show {
          width: 55rem;
          opacity: 1;
          border-radius: 5rem;
          border: 0.7rem solid #000;
          height: 8.2rem;
          transition: width 0.5s linear;
        }
      }
      .show {
        width: 8.2rem;
        display: inline-flex;
        align-items: center;
        border-radius: 5rem;
        height: 8.2rem;
        margin-left: -8.2rem;
        transition: width 0.5s linear;
        overflow: hidden;
        border: 0.7rem solid #000;

        span {
          font-size: 3.2rem;
          font-family: "DTL";
          margin-right: 2.5rem;
          margin-left: 9.1rem;
        }
        .copy {
          width: 2.5rem;
          height: 2.5rem;
          display: block;
          cursor: pointer;
        }
      }
      .left_link {
        width: 8.2rem;
        height: 8.2rem;
        display: block;

        // flex: 1;
      }
    }
  }
}
@media only screen and (max-width: 765px) {
  .story {
    &.mobile {
      text-align: left;

      .pc {
        display: none;
      }
      // .my_bottom {
      //   display: none;
      // }
      // padding: 0 8rem;
      .nft {
        display: none;
      }
      .main {
        position: relative;
        z-index: 2;
        padding: 10.2rem 2.4rem 0 3rem;
        margin-bottom: 8rem;
        .title1 {
          // color: $sub;
          font-size: 3rem;
          text-align: left;
          margin-bottom: 3.2rem;
          letter-spacing: -0.04rem;
          padding: 0;
          img {
            display: none;
          }
          &::after {
            display: none;
          }
        }
        .r-gif {
          width: 13.5rem;
          height: auto;
          position: absolute;
          top: 1.4rem;
          right: 2.5rem;
          content: url("../assets/mobile_gif/point.gif");
        }
        .info {
          font-size: 1.6rem;
          line-height: 2.2rem;
          text-align: left;
          padding: 0;
          .light {
            font-size: 8rem;
            // color: #6640b3;
            // font-weight: 500;
          }
        }
      }
    }
  }
}
</style>
