<template>
  <div class="road page-ani">
    <div class="roadbg">
      <picture>
        <source
          media="(max-width: 756px)"
          srcset="@/assets/road/mobile_Roadmap.png"
        />
        <img src="@/assets/road/roadmap.png" alt="" />
      </picture>
    </div>
    <div class="topInfo">
      <section class="box1">
        <div class="mid">
          <h1 class="title2">IN PLANNING</h1>
          <div class="title3">Legendary Builders Program</div>
          <p class="info">
            Legendary builders are the core supporters of the “Destroy and…”
            community and <br class="pc" />core partners who will participate in
            the future co-construction. It includes both<br class="pc" />
            Destroy and.. Holders, as well as the industry’s top KOL, Web3
            contributors, NFT<br class="pc" />
            artists, skateboarding/hip-hop and ambassadors in various fields.
            Each Legendary<br class="pc" />
            Builder will get Super Scarce NFT Airdrop.<br /><br />
            The total number of NFTs is 300, of which 200 are airdropped to
            destroy and Holders who collect three of<br class="pc" />
            the five races. The other 100 NFTs are targeted subscriptions by top
            KOL, Web3 contributors, NFT artists,<br class="pc" />
            skateboarding/hip-pop and other ambassadors in various fields in the
            industry. The subscription<br class="pc" />
            qualification is purchase NFT of any three races in the secondary
            market.
          </p>
        </div>
        <div class="gifZ">
          <picture>
            <source
              media="(max-width: 756px)"
              srcset="@/assets/newRoadMap/right-circle_m.png"
            />
            <img src="@/assets/newRoadMap/right-circle.png" alt="" />
          </picture>
        </div>
      </section>
      <section class="box2">
        <div class="game">
          <div class="left-info">
            <h1 class="title1">IN PLANNING</h1>
            <h1 class="title2">SLOT MACHINE GAME</h1>
            <h3 class="title3">
              Slot Machine is our Original Minting Mechanism.<br class="pc" />
              Users can get:
            </h3>
            <ul class="lists">
              <li>
                Completely randomly generated NFT, users can get the NFT they<br
                  class="pc"
                />
                want in a gamified way.
              </li>
              <br />
              <li>
                Part of the slot machine revenue is accumulated into the
                prize<br class="pc" />
                pool, lucky users will receive a large prize pool!
              </li>
              <br />
              <li>
                Users also have a chance to win blue chip NFTs like Azuki
                BAYC<br class="pc" />
                and Moonbirds.
              </li>
              <br />
              <li>Holding Destroy and.. NFT is the ticket to participate.</li>
            </ul>
          </div>
          <div class="right-info">
            <img src="@/assets/newRoadMap/p1.png" alt="" />
          </div>
        </div>
        <div class="skate">
          <div class="top-title">
            <div class="left-info">
              <h1 class="title1">IN PLANNING</h1>
              <h1 class="title2">DESTROY AND X EVENTS</h1>
            </div>
            <div class="right-info">
              <h2 class="title3">Destroy and Skate</h2>
              <p class="info">
                Sponsoring skateboarding competitions and great
                <br class="pc" />skaters to spread the ‘destroy and rebuild’
                spirit.
              </p>
            </div>
          </div>
          <div class="round-img">
            <img src="@/assets/newRoadMap/p2.png" alt="" />
          </div>
        </div>
        <div class="hip-pop">
          <div class="left-info">
            <h1 class="title1">Destroy and Hip-pop</h1>
            <p class="info">
              To spread the spirit of ‘destroy and rebuild’ by<br class="pc" />
              hosting hip-pop festivals.
            </p>
          </div>
          <div class="right-info">
            <picture>
              <source
                media="(max-width: 756px)"
                srcset="@/assets/newRoadMap/p4_m.png"
              />
              <img src="@/assets/newRoadMap/p4.png" alt="" />
            </picture>
            <picture>
              <source
                media="(max-width: 756px)"
                srcset="@/assets/newRoadMap/p3_m.png"
              />
              <img src="@/assets/newRoadMap/p3.png" alt="" />
            </picture>
          </div>
        </div>
      </section>
      <section class="box4">
        <ul class="lists">
          <li>
            <h1 class="title1">EXPLORING</h1>
            <h1 class="title2">
              NFT CO-CREATION AND<br />
              REVENUE SHARING
            </h1>
          </li>
          <li>
            <p class="info">
              We are exploring a new organizational form, allowing our
              <br class="pc" />Holders to participate in the creation of
              subsequent NFT<br class="pc" />
              works and revenue sharing to grow with us.
            </p>
          </li>
        </ul>
        <ul class="lists">
          <li>
            <h1 class="title1">EXPLORING</h1>
            <h1 class="title2">DESTROY AND <br />TOKENOMICS</h1>
          </li>
          <li>
            <p class="info">
              We are exploring the possibility of using tokens<br class="pc" />
              to convey the spirit of ‘destroy and rebuild’<br class="pc" />
              more effectively.
            </p>
          </li>
        </ul>
      </section>
    </div>
    <bottom />
  </div>
</template>
<script>
import { defineComponent, reactive, toRefs } from "vue";
import Bottom from "../components/Bottom.vue";
export default defineComponent({
  components: { Bottom },
  setup() {
    const data = reactive({});
    return {
      ...toRefs(data),
    };
  },
});
</script>
<style scoped lang="scss">
@import "@/common/css/common.scss";

.road {
  overflow-y: auto;
  .ios {
    display: none;
  }
  .roadbg {
    position: fixed;
    z-index: 2;
    width: 100%;
    height: calc(100vh - 15.7rem);
    img {
      top: 40%;
      position: absolute;
      display: block;
      width: 101vw;
      height: auto;
      transform: translate(-1.8%, -50%);
    }
  }
  // .topInfo {
  //   // position: relative;
  //   // z-index: 2;
  // }
  .title1 {
    line-height: 8rem;
  }
  .box1 {
    position: relative;
    z-index: 3;
    display: flex;
    justify-content: space-between;
    .mid {
      padding: 15.5rem 0 17rem 12.2rem;
      text-align: left;
      .title2 {
        color: $title;
        letter-spacing: 0.2rem;
        display: inline-block;
        font-size: 7rem;
        line-height: 7rem;
        position: relative;
      }
      .title3 {
        color: $info;
        font-size: 3.5rem;
        letter-spacing: -0.03rem;
        // margin-top: 4.5rem;
        // font-weight: bold;
      }
      .info {
        margin-top: 5.9rem;
        font-size: 2.5rem;
      }
    }
    .gifZ {
      margin-top: 12.2rem;
      transform: translateX(-13.5rem);
      img {
        width: 32.3rem;
        height: auto;
        display: block;
        margin: 0 auto;
      }
    }
  }
  .box2 {
    background: $main;
    text-align: left;
    .game {
      padding: 8.6rem 12.3rem 15.6rem;
      position: relative;
      color: $title;
      .left-info {
        position: relative;
        z-index: 6;
        .title1 {
          font-size: 10rem;
          line-height: 10rem;
        }
        .title2 {
          font-size: 6rem;
        }
        .title3 {
          font-size: 2.7rem;
        }
      }
      .lists {
        margin-top: 5rem;
        li {
          color: $sub;
          font-size: 2.5rem;
          position: relative;
          &::before {
            content: "";
            background: $sub;
            display: inline-block;
            width: 0.8rem;
            height: 0.8rem;
            border-radius: 50%;
            position: absolute;
            left: -1.6rem;
            top: 1.3rem;
            // margin-right: 1rem;
          }
        }
      }
      .right-info {
        position: absolute;
        top: 7.2rem;
        right: 0;
        z-index: 5;
        img {
          width: 114.8rem;
          height: auto;
          display: block;
        }
      }
    }
    .skate {
      position: relative;
      z-index: 5;
      padding-bottom: 13.5rem;
      border-bottom: 0.2rem solid $sub;
      border-top: 0.2rem solid $sub;
      .top-title {
        display: flex;
        align-items: flex-end;
        padding: 13.5rem 12.2rem;
        .left-info {
          color: #fff;
          margin-right: 9.6rem;
          white-space: nowrap;
          .title1 {
            font-size: 8.8rem;
            line-height: 8.8rem;
          }
          .title2 {
            font-size: 5.3rem;
            line-height: 5.3rem;
          }
        }
        .right-info {
          color: $sub;
          .title3 {
            font-size: 5rem;
            white-space: nowrap;
          }
          .info {
            font-size: 2.5rem;
            line-height: 3rem;
          }
        }
      }
      .round-img {
        width: 100%;
        img {
          width: 100%;
          height: auto;
          display: block;
        }
      }
    }
    .hip-pop {
      padding-top: 13.6rem;
      position: relative;
      z-index: 5;
      .left-info {
        color: $sub;
        position: absolute;
        left: 12.3rem;
        z-index: 6;

        .title1 {
          font-size: 5rem;
          color: $sub;
          line-height: 5rem;
        }
        .info {
          font-size: 2.5rem;
          margin-top: 1rem;
          letter-spacing: 0.1rem;
        }
      }
      .right-info {
        display: flex;
        justify-content: flex-end;
        img {
          width: 63.9rem;
          height: auto;
          display: block;
          &:nth-child(1) {
            transform: translateX(1.9rem);
          }
        }
      }
    }
  }
  .box3 {
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 0.4rem solid $main;
    padding: 6.9rem 0 11.1rem;
    position: relative;
    z-index: 3;
    .lists {
      text-align: left;
      padding-top: 4rem;
      li {
        margin-bottom: 3rem;
        h2 {
          color: $title2;
          font-size: 5rem;
        }
        p {
          color: $main;
          font-size: 3rem;
        }
        &:nth-child(1) {
          margin-bottom: 10rem;

          .title1 {
            font-size: 10rem;
          }
          .title2 {
            font-size: 6rem;
            color: $title2;
          }
        }
      }
    }
    .circle {
      position: relative;
      margin-left: 16.4rem;
      img {
        &.circle1 {
          width: 46.9rem;
          height: 47.2rem;
          display: block;
        }
        &.circle2 {
          width: 39.7rem;
          height: 39.3rem;
          display: block;
          position: absolute;
          top: 4.6rem;
          left: 4.6rem;
        }
      }
    }
  }
  .box4 {
    display: flex;
    // justify-content: center;
    // align-items: center;
    border-bottom: 0.4rem solid $main;
    padding: 14.4rem 0 21.1rem 12.3rem;
    position: relative;
    z-index: 3;
    .lists {
      text-align: left;
      li {
        p {
          color: $main;
          font-size: 2.5rem;
        }
        &:nth-child(1) {
          margin-bottom: 4.6rem;
          .title1 {
            font-size: 10rem;
          }
          .title2 {
            font-size: 6rem;
            line-height: 6rem;
            color: $title2;
            margin-top: 3rem;
            white-space: nowrap;
          }
        }
      }
      &:nth-child(1) {
        margin-right: 22.5rem;
        li {
          :nth-child(2) {
            margin-bottom: 4rem;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 765px) {
  .road {
    overflow-y: auto;
    text-align: left;
    overflow-x: hidden;
    .pc {
      display: none;
    }
    .ios {
      display: block;
    }
    // .topInfo {
    //   // position: relative;
    //   // z-index: 2;
    // }
    p {
      line-height: 1.8rem;
    }
    .title1 {
      font-size: 2.8rem;
      color: #fff;
      text-align: left;
      letter-spacing: 0.04rem;
      width: auto;
      margin: 0 auto;
      line-height: unset;
    }
    .total {
      margin-top: 5.9rem;
      .title1 {
        font-size: 2rem;
      }
    }
    .roadbg {
      position: fixed;
      z-index: 3;
      width: 100%;
      height: auto;
      img {
        top: 10rem;
        position: absolute;
        display: block;
        width: 13rem;
        height: auto;
        right: -3.1rem;
        transform: translate(0, 0);
        content: url("../assets/road/mobile_Roadmap.png");
      }
    }
    .box1 {
      position: relative;
      z-index: 3;
      display: block;
      flex-direction: column-reverse;
      justify-content: space-between;
      overflow: hidden;
      padding-top: 15.7rem;
      .mid {
        padding: 0 4rem 4.6rem 2.9rem;
        text-align: left;
        .title2 {
          color: $title;
          letter-spacing: 0.2rem;
          display: inline-block;
          font-size: 2.5rem;
          line-height: 2.5rem;
          position: relative;
        }
        .title3 {
          text-transform: uppercase;
          color: $title;
          font-size: 1.5rem;
          letter-spacing: -0.03rem;
          // margin-top: 4.5rem;
          // font-weight: bold;
        }
        .info {
          margin-top: 1.4rem;
          font-size: 1.6rem;
          line-height: 1.8rem;
        }
      }
      .gifZ {
        // margin-top: 8.9rem;
        align-self: flex-end;
        position: absolute;
        right: 0;
        top: 8.9rem;
        transform: translateX(-2.4rem);
        margin-top: 0;
        img {
          width: 7.7rem;
          height: auto;
          display: block;
          margin: 0 auto;
        }
      }
    }
    .box2 {
      background: $main;
      text-align: left;
      .game {
        padding: 4rem 4.8rem 1.7rem 2.9rem;
        position: relative;
        color: $title;
        .left-info {
          position: relative;
          z-index: 6;
          .title1 {
            font-size: 2.8rem;
            line-height: 2.8rem;
            letter-spacing: -0.05em;
          }
          .title2 {
            font-size: 2rem;
            letter-spacing: -0.03em;
          }
          .title3 {
            font-size: 1.6rem;
            color: $sub;
            line-height: 1.8rem;
            letter-spacing: -0.02rem;
          }
        }
        .lists {
          margin-top: 2rem;
          li {
            color: $sub;
            font-size: 1.6rem;
            line-height: 1.8rem;
            letter-spacing: -0.02rem;
            // list-style: disc;
            &::before {
              content: "";
              background: $sub;
              display: inline-block;
              width: 0.4rem;
              height: 0.4rem;
              border-radius: 50%;
              left: -1rem;
              top: 0.72rem;
              // margin-right: 0.5rem;
            }
          }
        }
        .right-info {
          position: relative;
          z-index: 5;
          transform: translateX(calc(-2.9rem - 5vw));
          top: 0;
          img {
            width: 110vw;
            height: auto;
            display: block;
          }
        }
      }
      .skate {
        position: relative;
        z-index: 5;
        padding-bottom: 5.7rem;
        border-bottom: 0.2rem solid $sub;
        border-top: 0.2rem solid $sub;
        .top-title {
          display: block;
          padding: 5.1rem 2.9rem;
          .left-info {
            color: #fff;
            margin-right: 0;
            .title1 {
              font-size: 2.8rem;
              line-height: 2.8rem;
              letter-spacing: -0.05em;
            }
            .title2 {
              font-size: 2rem;
              line-height: 2rem;
              letter-spacing: -0.02em;
            }
          }
          .right-info {
            color: $sub;
            font-size: 1.6rem;
            margin-top: 1.5rem;
            letter-spacing: 0.01rem;
            padding-right: 2.3rem;
            .title3 {
              font-size: 1.8rem;
              color: #fff;
            }
            .info {
              font-size: 1.6rem;
              line-height: 1.8rem;
            }
          }
        }
        .round-img {
          width: 100%;
          img {
            width: 100%;
            height: auto;
            display: block;
          }
        }
      }
      .hip-pop {
        padding-top: 3.8rem;
        position: relative;
        z-index: 5;
        .left-info {
          color: $sub;
          position: relative;
          z-index: 6;
          left: 0;
          padding: 0 5rem 3.8rem 2.8rem;
          .title1 {
            font-size: 1.8rem;
            color: #fff;
            line-height: 2.6rem;
          }
          .info {
            font-size: 1.6rem;
            margin-top: 0.1rem;
            letter-spacing: 0.01rem;
            padding-right: 0;
          }
        }
        .right-info {
          display: flex;
          justify-content: flex-end;
          img {
            width: 50vw;
            height: auto;
            display: block;
            &:nth-child(1) {
              transform: translateX(0);
            }
          }
        }
      }
    }

    .box4 {
      display: flex;
      justify-content: center;
      // align-items: center;
      flex-flow: column;
      border-bottom: 0.08rem solid $main;
      padding: 4.9rem 0 10rem 2.7rem;
      .lists {
        text-align: left;
        margin-top: 2rem;
        li {
          h2 {
            color: $title2;
            font-size: 1.8rem;
          }
          p {
            color: $main;
            font-size: 1.6rem;
            margin-top: 0.6rem;
            line-height: 1.8rem;
            padding-right: 3rem;
            letter-spacing: -0.02rem;
          }
          &:nth-child(1) {
            margin-bottom: 2.4rem;
            .title1 {
              font-size: 2.8rem;
              letter-spacing: -0.03em;
            }
            .title2 {
              font-size: 2rem;
              color: $title2;
              line-height: 2rem;
              margin-top: 0.5rem;
              letter-spacing: -0.01em;
            }
          }
        }
        &:nth-child(1) {
          margin-right: 0;
          margin-bottom: 6rem;
          &::after {
            content: "";
            width: 100vw;
            position: absolute;
            left: 0;
            margin-top: 3.6rem;
            border-bottom: 0.2rem solid $main;
          }
          li {
            :nth-child(2) {
              margin-bottom: 0.8rem;
            }
          }
        }
      }
    }
  }
}
</style>
