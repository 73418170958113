<template>
  <div class="faq page-ani">
    <!-- <h1 class="title1">FAQ</h1> -->
    <div class="topfaq"><img src="@/assets/faq/FAQ.png" alt="" /></div>
    <div class="rightfaq pc">
      <img src="@/assets/gif/question.gif" alt="" />
    </div>
    <ul class="faqList">
      <li>
        <h2 class="title1">WHY ARE YOU DOING DESTROY AND…?</h2>
        <p class="info">
          In our opinion, NFT is a cultural phenomenon, and the
          <br class="pc" />gameplay and pictures help to spread the culture. We
          want to pass on “destroy and rebuild”,<br class="pc" />
          that is, to stick to what you love and dare to push through obstacles.
          In the present <br class="pc" />day, people from all around the globe
          suffer under the predicament of war, pandemic, inflation, et
          <br class="pc" />cetera. Many have flickering sparks in within, but
          are unable to ignite and set<br class="pc" />
          their hearts on fire. We hope the spirit we want to<br class="pc" />
          spread can help them.
        </p>
      </li>
      <li>
        <h2 class="title1">
          WHAT IS<br class="ios" />
          DESTROY AND…?
        </h2>
        <p class="info">
          Destroy and… is new avatars on the Ethereum blockchain with each
          avatar built from a destruction<br class="pc" />
          of unique personas. <br /><br />
          Destroy and… is our spirit and culture from destroy and rebuild. We
          hope to build a community and<br class="pc" />
          pass on the spirit of ‘destroy and ‘rebuild’ together in the future.
        </p>
      </li>
      <li>
        <h2 class="title1">
          WHAT IS THE TOTAL<br class="pc" />
          SUPPLY<br class="ios" />
          AND MINTING MECHANISM?
        </h2>
        <p class="info">
          5,000 in total. 5% for the team, 95% for sales <br /><br />
          Whitelist Mint <br />
          Time: July 26, 23:00-July 27, 23:00 EDT<br class="ios" />
          Price: 0.04E (up to 2 mint per address)<br /><br />
          Public Sales<br />
          Time: July 27, 23:00-July 28, 23:00 EDT<br class="ios" />
          Price: 0.08E
        </p>
      </li>
      <li>
        <h2 class="title1">
          ARE THERE ANY BENEFITS WITH<br class="pc" />
          NFT HOLDER?
        </h2>
        <ul class="info">
          <li>
            SP NFT will be airdropped once someone collects three of the five races.
          </li>
          <br />
          <li>
            Access to our upcoming slot machine. Users can create their own NFT
            by slot machine and lucky <br class="pc" />ones can get USDT rewards
            gathered from minting.
          </li>
          <br />
          <li>Co-creation of NFT and share the revenue</li>
          <br />
          <li>Free tickets of our Destroy and X events</li>
          <br />
          <li>Ownership and commercial usage rights</li>
          <br />
          <li>Token airdrop</li>
        </ul>
      </li>
      <li>
        <h2 class="title1">HOW TO GET WL?</h2>
        <p class="info">
          Please check
          <a href="https://discord.com/invite/destroyand" target="_blank"
            >discord</a
          >
          ｜how-to-whitelist.
          <br />
          <br />

          FYI: We don’t mind if the community is not active 24/7. We hope the
          ones that are on the whitelist<br class="pc" />
          share the same values with us, to “destroy and rebuild”.
        </p>
      </li>
    </ul>
    <bottom />
  </div>
</template>
<script>
import { defineComponent, reactive, toRefs } from "vue";
import Bottom from "../components/Bottom.vue";
export default defineComponent({
  components: { Bottom },
  setup() {
    const data = reactive({});
    return {
      ...toRefs(data),
    };
  },
});
</script>
<style scoped lang="scss">
@import "@/common/css/common.scss";
.faq {
  width: 100%;
  overflow: auto;
  position: relative;
  .ios {
    display: none;
  }
  .topfaq {
    position: fixed;
    z-index: 1;
    width: 100%;
    height: calc(100vh - 15.7rem);
    top: 3.2rem;
    img {
      position: absolute;
      display: block;
      width: 108.5rem;
      height: auto;
      transform: translate(-5.5rem);
    }
  }
  .rightfaq {
    position: absolute;
    z-index: 1;
    width: 60.6rem;
    height: calc(100vh - 15.7rem);
    top: 4.1rem;
    right: -7rem;
    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
  .faqList {
    margin-top: 22.1rem;
    margin-bottom: 5vh;
    position: relative;
    z-index: 2;
    > li {
      padding: 7.9rem 0 10rem;
      .title1 {
        text-align: center;
        color: $title2;
        // font-family: "RB";
        // padding: 7.9rem 0 3rem;
        font-size: 6.5rem;
        line-height: 7rem;
        padding-bottom: 5.5rem;
      }
      &:first-child {
        .title1 {
          font-size: 5.5rem;
        }
      }
      &:nth-child(2),
      &:nth-child(5) {
        .title1 {
          font-size: 7rem;
        }
      }
      .info {
        font-size: 3rem;
        font-weight: 500;
        a {
          color: $title2;
          font-size: 3rem;
          font-weight: 500;
          text-decoration-line: underline;
        }
        li::before {
          content: "";
          background: $main;
          display: inline-block;
          width: 1rem;
          height: 1rem;
          border-radius: 50%;
          margin-bottom: 0.4rem;
          margin-right: 1rem;
        }
      }
      .title3 {
        font-size: 5rem;
      }
      &:not(:nth-child(1)) {
        position: relative;
        &::before {
          content: "";
          position: absolute;
          width: 100%;
          border-top: 0.4rem solid $main;
          left: 0;
          top: 0;
        }
      }
    }
  }
}
@media only screen and (max-width: 765px) {
  .faq {
    overflow-x: hidden;
    .pc {
      display: none;
    }
    .ios {
      display: block;
    }
    .title1 {
      text-align: center;
      font-size: 2.5rem;
    }
    .topfaq {
      position: fixed;
      z-index: 1;
      width: 100%;
      height: auto;
      top: 4.6rem;
      img {
        position: absolute;
        display: block;
        width: 20.6rem;
        height: auto;
        transform: translate(1rem);
      }
    }
    .rightfaq {
      display: none;
    }
    .faqList {
      margin-top: 8.8rem;
      margin-bottom: 4rem;
      > li {
        padding: 4rem 2.9rem 4.7rem 2.8rem;
        text-align: left;
        .title1 {
          text-align: left;
          font-size: 2.5rem !important;
          // padding: 7.9rem 0 3rem;
          padding-bottom: 1.4rem;
          line-height: 2.5rem;
        }
        &:nth-child(3) {
          .title1 {
            font-size: 2rem !important;
            line-height: 2.2rem !important;
          }
        }
        .info {
          font-size: 1.6rem;
          font-family: "PF_bold";
          letter-spacing: -0.05rem;
          a {
            font-size: 1.6rem;
            font-family: "PF_bold";
          }
          li::before {
            content: "";
            background: $main;
            display: inline-block;
            width: 0.5rem;
            height: 0.5rem;
            border-radius: 50%;
            margin-bottom: 0.3rem;
            margin-right: 0.4rem;
          }
        }
        .title3 {
          font-size: 2.6rem;
        }
        &:not(:nth-child(1)) {
          position: relative;
          &::before {
            content: "";
            position: absolute;
            width: 100%;
            border-top: 0.2rem solid $main;
            left: 0;
            top: 0;
          }
        }
        &:nth-child(2)::after {
          content: "";
          position: absolute;
          width: 17.2rem;
          height: 9.7rem;
          background-image: url("../assets/mobile_gif/question.gif");
          right: -2rem;
          background-repeat: no-repeat;
          background-size: 100%;
          top: -5.2rem;
        }
        &:last-child {
          padding-right: 4rem;
        }
      }
    }
  }
}
</style>
