<template>
  <div class="box">
    <div class="contain">
      <swiper
        :class="{ change: mySwiper }"
        :observe="true"
        :observeParents="true"
        :modules="modules"
        effect="coverflow"
        :slides-per-view="3"
        :space-between="0"
        :autoplay="{ delay: 2000, disableOnInteraction: false }"
        :centeredSlides="true"
        :loop="true"
        :coverflowEffect="coverflow"
      >
        <swiper-slide>
          <div class="slideimg">
            <img src="@/assets/gallery/1.png" alt="" />
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="slideimg">
            <img src="@/assets/gallery/3.png" alt="" />
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="slideimg">
            <img src="@/assets/gallery/2.png" alt="" />
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="slideimg">
            <img src="@/assets/gallery/4.png" alt="" />
          </div>
        </swiper-slide>
        <!-- <swiper-slide>
          <div class="slideimg">
            <img src="@/assets/gallery/6.png" alt="" />
          </div>
        </swiper-slide> -->
        <swiper-slide>
          <div class="slideimg">
            <img src="@/assets/gallery/5.png" alt="" />
          </div>
        </swiper-slide>
      </swiper>
      <div class="rightInfo">
        <div class="whiteTitle" v-if="nowStatus === 'waitingWhite'">
          WHITELIST MINT
        </div>
        <div
          v-else-if="
            nowStatus === 'publicSale' || nowStatus === 'waitingPublic'
          "
          class="publicTitle"
        >
          <img src="@/assets/mint/public.png" alt="" />
        </div>
        <template
          v-if="nowStatus === 'waitingWhite' || nowStatus === 'waitingPublic'"
        >
          <div class="count">
            <div class="info">
              <span class="line">WILL BEGIN IN</span>
              <span class="day">{{ mintTimer.day }}</span>
              <span class="line">DAYS</span>
            </div>
            <div class="times">
              <span>{{ mintTimer.hour }}</span>
              <span>{{ mintTimer.minute }}</span>
              <span>{{ mintTimer.second }}</span>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="title" v-if="nowStatus === 'whiteSale'">
            WHITELIST
            <br />
            MINT
          </div>
          <div class="title2" v-else-if="nowStatus === 'soldOut'">SOLD OUT</div>
          <div class="name">GET YOUR OWN NFTS</div>
          <div class="minted">
            MINTED
            <span class="num">{{ minted }}/{{ total }}</span>
          </div>
          <template v-if="nowStatus !== 'soldOut'">
            <button
              class="mint-btn changeOpacity"
              v-if="$store.state.isConnect && $store.state.networkSuccess"
              @click="showDrop('mint')"
            >
              <span>MINT</span>
              <img src="@/assets/mint/arrow_right_able.png" alt="" />
            </button>
            <button
              class="mint-btn"
              v-else-if="!$store.state.isConnect"
              @click.stop.prevent="$store.state.walletDropShow = true"
            >
              <span>MINT</span>
              <img src="@/assets/mint/arrow_right.png" alt="" />
            </button>
            <button class="mint-btn" v-else-if="!$store.state.networkSuccess">
              <span>MINT</span>
              <img src="@/assets/mint/arrow_right.png" alt="" />
            </button>
          </template>
          <template v-else>
            <button
              class="mint-btn minted"
              v-if="!$store.state.isConnect"
              @click.stop.prevent="showDrop"
            >
              <span>VIEW YOUR NFTS</span>
            </button>
            <button
              class="mint-btn minted"
              v-else-if="!$store.state.isEnd || nftImgList.length === 0"
            >
              <span>VIEW YOUR NFTS</span>
            </button>
            <button
              class="mint-btn changeOpacity minted"
              v-else
              @click="showDrop('nfts')"
            >
              <span>VIEW YOUR NFTS</span>
            </button>
            <router-link to="/gallery">
              <button class="mint-btn changeOpacity gallery">
                <span>GALLERY</span>
              </button>
            </router-link>
          </template>
        </template>
      </div>
    </div>
    <Bottom />
    <div class="drop" v-if="showMint">
      <div class="card">
        <div class="close" @click="showMint = false">
          <img src="@/assets/mint/close_p.png" alt="" />
        </div>
        <div class="rightInfo">
          <div class="topbox">
            <loadingVideo :status="videoNum" />
            <div class="waitingDrop" v-if="isWaiting">
              <span>Please wait…</span>
            </div>
          </div>
          <div class="minted">
            <!-- MINTED<span class="num">{{ hasMint }}/{{ mintLimit }}</span> -->
          </div>
          <div class="mid">
            <div class="count">
              <button class="reduce" @click.stop.prevent="changeNum('reduce')">
                <img src="@/assets/mint/reduce.png" alt="" />
              </button>
              <input type="number" class="num" v-model="mintnum" />
              <!-- <span class="num">{{ mintnum }}</span> -->
              <button class="add" @click.stop.prevent="changeNum('add')">
                <img src="@/assets/mint/add.png" alt="" />
              </button>
            </div>
            <span
              class="max"
              @click="() => (mintnum = mintLimit - hasMint || 1)"
            >
              Mint Max
            </span>
          </div>
          <div class="hasMax" v-show="isMax">
            {{ toast }}
          </div>
          <button class="btn" @click="goMint">
            Mint {{ mintnum }} for
            {{ Web3.changeValue(mintPrice * mintnum) }} ETH
          </button>
        </div>
      </div>
    </div>
    <div class="drop" v-show="showMintedNft" id="nftDrop">
      <div v-show="$store.state.openState === 'new'" class="unknown-box">
        <div class="close" @click.stop.prevent="showMintedNft = false">
          <img src="@/assets/mint/close.png" alt="" />
        </div>
        <div class="boxImg">
          <div class="aniHidden">
            <img class="blurimg" src="@/assets/mint/unknown.jpg" alt="" />
            <div class="lock">
              <img src="@/assets/mint/lock.png" alt="" @click="showVideo" />
            </div>
          </div>
          <video
            muted
            :autoplay="false"
            id="openvideo"
            class="openvideo"
            preload="auto"
            :webkit-playsinline="true"
            :playsinline="true"
            x5-video-player-type="h5"
            src="@/assets/video/open_pc.mp4"
          >
            <source src="@/assets/video/open_pc.mp4" type="video/mp4" />
          </video>
        </div>
      </div>
      <div class="card nfts" v-show="$store.state.openState !== 'new'">
        <div class="rightInfo">
          <div class="close" @click="showMintedNft = false">
            <img src="@/assets/mint/close_p.png" alt="" />
          </div>
          <div class="title">YOUR NFTS</div>
          <div style="width: 100%">
            <ul class="nftlist" v-if="nftsList.length > 0">
              <li v-for="(item, index) in nftsList" :key="`nft_${index}`">
                <div class="nft">
                  <img
                    v-lazy="newImageUrl(item.id.tokenId)"
                    @click="handlePreview(index)"
                    alt=""
                  />
                  <div
                    class="more"
                    v-show="nftsList.length >= 3 && index === 2"
                    @click="handlePreview(0)"
                  >
                    More...
                  </div>
                </div>
                <div class="nft-name">{{ item.metadata.name }}</div>
              </li>
            </ul>
            <ul class="nftlist" v-else>
              <li v-for="index in hasMint" :key="`box_${index}`">
                <img src="@/assets/gif/loading.gif" alt="" />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <preview-img
    :nftImgList="nftImgList"
    :imgIndex="imgIndex"
    :style="{ opacity: waitingOpenBox ? 0 : 1, zIndex: 2021 }"
    v-if="showPreview"
    @close="() => (showPreview = false)"
  />
  <!-- <div class="drop"></div> -->
</template>
<script>
import {
  defineComponent,
  nextTick,
  onBeforeUnmount,
  onMounted,
  reactive,
  toRefs,
  watch,
} from "vue";
import Bottom from "./Bottom.vue";
import { useStore } from "vuex";
import loadingVideo from "./Loading.vue";
import previewImg from "./PreviewImg.vue";
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import { EffectCoverflow, Autoplay } from "swiper";
import Web3 from "@/common/js/utils";
// Import Swiper styles
import "swiper/swiper.scss";
export default defineComponent({
  components: {
    Swiper,
    SwiperSlide,
    Bottom,
    loadingVideo,
    previewImg,
  },
  setup() {
    const store = useStore();
    const data = reactive({
      mySwiper: false,
      coverflow: {
        rotate: 0, //slide做3d旋转时Y轴的旋转角度。默认50。
        stretch: 13, //每个slide之间的拉伸值，越大slide靠得越紧。 默认0。
        depth: 85, //slide的位置深度。值越大z轴距离越远，看起来越小。 默认100。
        modifier: 3.2, //depth和rotate和stretch的倍率，相当于depth*modifier、rotate*modifier、stretch*modifier，值越大这三个参数的效果越明显。默认1。
        slideShadows: false, //开启slide阴影。默认 true。
      },
      whiteTotal: 5000,
      total: 5000,
      minted: 5000,
      showMint: false,
      showMintedNft: false, //查看已经mint的nft
      mintnum: 1,
      mintLimit: 0,
      nftsList: [],
      nftImgList: [],
      imgIndex: 0,
      showPreview: false, //预览图片
      mintPrice: null,
      showWalletDrop: false, //钱包
      hasClick: false,
      hasMint: 0,
      nowStatus: "", //waitingWhite,waitingPublic,whiteSale,publicSale,soldOut
      publicSaleStartTime: null,
      mintTimer: null,
      isMax: true,
      videoNum: "1",
      toast: "",
      myTimer: null, //定时器
      isWaiting: false, //等待遮罩层
      mintlistPrice: "",
      publicPrice: "",
      waitingOpenBox: false,
    });
    const changeNum = (type) => {
      if (type === "add" && data.mintnum < data.mintLimit - data.hasMint) {
        data.mintnum++;
      } else if (type === "reduce" && data.mintnum > 1) {
        data.mintnum--;
      }
    };

    const showDrop = async (type) => {
      data.isWaiting = false;
      data.toast = "";
      data.mintnum = 1;
      if (store.state.account && localStorage.getItem(store.state.account)) {
        store.state.openState = localStorage.getItem(store.state.account);
      }
      if (store.state.isConnect) {
        if (type === "mint") {
          await getMinted();
          Web3.numberMinted(store.state.account).then((res) => {
            data.hasMint = res * 1;
          });
          Web3.saleConfig().then((res) => {
            data.publicSaleStartTime = res.publicSaleStartTime * 1;
            data.mintlistPrice = res.mintlistPrice * 1;
            data.publicPrice = res.publicPrice * 1;
            const nowTime = new Date().getTime();
            if (
              nowTime / 1000 < data.publicSaleStartTime ||
              data.publicSaleStartTime === 0
            ) {
              data.mintLimit = 2;
              data.mintPrice = data.mintlistPrice;
            } else {
              data.mintLimit = 100;
              data.mintPrice = data.publicPrice;
            }
            if (data.mintLimit - data.hasMint < data.mintnum) {
              data.toast = "Already reached the mint maximum";
            } else {
              data.toast = "";
            }
            data.videoNum = "1";
            data.showMint = true;
          });
        } else if (type === "nfts") {
          getNFTS();
          data.showMintedNft = true;
          if (store.state.openState === "new") {
            data.waitingOpenBox = true;
            data.showPreview = true;
          }
        } else {
          store.state.walletDropShow = true;
        }
      }
    };
    const getMinted = () => {
      Web3.totalSupply().then((res) => {
        let result = res * 1;
        data.minted = result;
      });
    };
    const getNFTS = () => {
      // store.state.account = "0xa0555469bb4a95d251dcf9d65FE47670DeA15fDb";
      Web3.getNFTS(store.state.account).then((res) => {
        let result = JSON.parse(JSON.stringify(res.ownedNfts));
        data.nftImgList = [];
        data.nftsList = [];
        if (result && result.length <= 3) {
          data.nftsList = result;
          for (let i = 0, ilen = result.length; i < ilen; i++) {
            const imagedata = {
              image: newImageUrl(result[i].id.tokenId),
              name: result[i].title,
            };
            // const imagedata = result[i].metadata;
            data.nftImgList.push(imagedata);
          }
        } else {
          for (let i = 0, ilen = result.length; i < ilen; i++) {
            if (i < 3) {
              data.nftsList.push(result[i]);
            }
            const imagedata = {
              image: newImageUrl(result[i].id.tokenId),
              name: result[i].title,
            };
            // const imagedata = result[i].metadata;
            data.nftImgList.push(imagedata);
          }
        }
      });
    };
    //修改图片url
    const newImageUrl = (id) => {
      const url = `https://bafybeicsafhrnux26shwhj3mmft7kxtzw3j6zsxynlwjm4csv2jrarl4va.ipfs.nftstorage.link/${Web3.toDecimal(
        id
      )}.png`;
      return url;
    };
    const goMint = async () => {
      data.toast = "";
      // store.state.hasClick = true;
      if (store.state.hasClick || !store.state.networkSuccess) {
        return false;
      }
      store.state.hasClick = true;
      await getMinted();
      if (data.minted < data.total) {
        if (store.state.isConnect) {
          await Web3.numberMinted(store.state.account).then((res) => {
            data.hasMint = res * 1;
          });
          if (data.mintnum > data.mintLimit - data.hasMint) {
            store.state.hasClick = false;
            data.isWaiting = false;
            data.toast = "Already reached the mint maximum";
            return false;
          }
          const nowTime = new Date().getTime();
          if (
            nowTime / 1000 < data.publicSaleStartTime ||
            data.publicSaleStartTime === 0
          ) {
            Web3.allowlist(store.state.account);
            if (store.state.isAllowList) {
              data.isWaiting = true;
              Web3.allowlistMint(
                store.state.account,
                data.mintPrice * 1,
                data.mintnum
              ).then((res2) => {
                if (res2) {
                  data.isWaiting = false;
                  data.videoNum = "3";
                  Web3.totalSupply().then((res) => {
                    if (res) {
                      let result = res * 1;
                      data.minted = result;
                    }
                  });
                  Web3.numberMinted(store.state.account).then((res) => {
                    data.hasMint = res * 1;
                    data.videoNum = "1";
                    data.toast = "Mint Successfully";
                  });
                } else {
                  data.isWaiting = false;
                }
              });
            } else {
              store.state.hasClick = false;
              data.isWaiting = false;
              data.toast = "not eligible for allowlist mint";
            }
          } else {
            data.isWaiting = true;

            Web3.publicSaleMint(
              store.state.account,
              data.mintPrice * 1,
              data.mintnum
            ).then((res2) => {
              if (res2) {
                data.isWaiting = false;
                data.videoNum = "3";
                Web3.totalSupply().then((res) => {
                  let result = res * 1;
                  data.minted = result;
                });
                Web3.numberMinted(store.state.account).then((res) => {
                  data.hasMint = res * 1;
                  data.videoNum = "1";
                  data.toast = "Mint Successfully";
                });
              } else {
                data.isWaiting = false;
                data.toast = "Mint unsuccessfully";
              }
            });
          }
        } else {
          store.state.walletDropShow = true;
          store.state.hasClick = false;
          data.isWaiting = false;
        }
      } else {
        store.state.hasClick = false;
        data.isWaiting = false;
      }
    };
    const timer = () => {
      const whiteSaleTime = 1658890800000;
      const whiteSaleEndTime = 1658977200000;
      const publicSaleTime = 1658977200000;
      const publicSaleEndTime = 1659063600000;
      const publicRevealTime = 1659613800000; // 1659614400000;

      var minusTime = "";
      //计算相差多少毫秒      未来时间.需要传参.月份"month-1"     当前时间
      if (data.minted >= data.total) {
        data.nowStatus = "soldOut";
      } else {
        if (new Date().getTime() < whiteSaleTime) {
          minusTime = whiteSaleTime - new Date().getTime();
          data.nowStatus = "waitingWhite";
          data.total = data.whiteTotal;
        } else if (new Date().getTime() < whiteSaleEndTime) {
          data.nowStatus = "whiteSale";
          data.total = data.whiteTotal;
          if (data.minted >= data.total) {
            minusTime = publicSaleTime - new Date().getTime();
            data.nowStatus = "waitingPublic";
          }
        } else if (new Date().getTime() < publicSaleTime) {
          minusTime = publicSaleTime - new Date().getTime();
          data.nowStatus = "waitingPublic";
        } else if (new Date().getTime() <= publicSaleEndTime) {
          minusTime = publicSaleTime - new Date().getTime();
          data.nowStatus = "publicSale";
          data.mintPrice = data.publicPrice;
          if (data.minted >= data.total) {
            data.nowStatus = "soldOut";
          }
        }
      }
      if (new Date().getTime() > publicSaleEndTime) {
        data.nowStatus = "soldOut";
        if (new Date().getTime() >= publicRevealTime) {
          store.state.isEnd = true;
          //清除定时器
          clearInterval(data.myTimer);
        }
      }
      //获取元素
      var days = parseInt(minusTime / 86400000); //1天 = 86400000毫秒
      var hours = parseInt((minusTime % 86400000) / 3600000); //1小时 = 3600000毫秒
      var minutes = parseInt((minusTime % 3600000) / 60000); //1分钟 = 60000毫秒
      var seconds = parseInt((minusTime % 60000) / 1000); //1秒 = 1000毫秒
      //空位补零.
      function checkTime(i) {
        //将0-9的数字前面加上0\.  1变为01
        if (i < 10) {
          return "0" + i;
        } else {
          return i;
        }
      }
      //带入函数,获取新元素

      hours = checkTime(hours);
      minutes = checkTime(minutes);
      seconds = checkTime(seconds);
      //获取最终时间
      data.mintTimer = {
        day: days,
        hour: hours,
        minute: minutes,
        second: seconds,
      };
      // document.getElementById("time").innerHTML =
      //   days + "天 &nbsp;" + hours + ":" + minutes + ":" + seconds;
    };
    const initData = () => {
      if (store.state.account) {
        // Web3.numberMinted(store.state.account).then((res) => {
        //   data.hasMint = res * 1;
        // });
      } else {
        data.hasMint = 0;
      }
    };
    //预览图片
    const handlePreview = (index) => {
      data.showMintedNft = false;
      data.showPreview = true;
      data.imgIndex = index;
    };
    const initWallet = async () => {
      // if (store.state.isConnect) {
      // getMinted();

      if (store.state.walletType === "walletConnect") {
        await Web3.InitWallet();
      } else if (store.state.walletType === "coinbase") {
        await Web3.InitCoinbase();
      } else if (store.state.walletType === "metamask") {
        await Web3.InitMetaMask();
      }
      initData();
    };
    //开合动画
    const showVideo = () => {
      // let startPlay = true;
      localStorage.setItem(store.state.account, "opened");
      var vplayer = document.getElementById("openvideo");
      const boxImg = document.getElementsByClassName("boxImg")[0];
      const nftDrop = document.getElementById("nftDrop");
      const aniHidden = document.getElementsByClassName("aniHidden")[0];
      nftDrop.style.zIndex = 2033;
      aniHidden.style.opacity = 0;
      vplayer.style.visibility = "visible";
      vplayer.play();

      // 播放结束
      //循环播放第三个视频
      vplayer.addEventListener("ended", function (e) {
        // mintimg.style.visibility = "visible";
        e.target.pause();
        nftDrop.style.background = "transparent";
        boxImg.style.opacity = 0;
        // data.showPreview = true;
        // setTimeout(() => {
        // }, 800);
        data.waitingOpenBox = false;
        setTimeout(() => {
          data.showMintedNft = false;
        }, 0);
      });
    };
    timer(); //立即调用这个函数。解决延时1sの问题
    data.myTimer = setInterval(timer, 1000); //将以上所有函数带入'间隔性定时器（A，1000）'
    onMounted(() => {
      // console.log(window)
      if (store.state.account) {
        getNFTS();
      }
      initWallet();
      nextTick(() => {
        document.getElementsByClassName("swiper")[0].style.flex = 0;
        setTimeout(() => {
          data.mySwiper = true;
          document.getElementsByClassName("swiper")[0].style.flex = 1;
        }, 100);
      });
    });
    onBeforeUnmount(() => {
      //清除定时器
      clearInterval(data.myTimer);
    });
    watch(
      () => [store.state.account, data.mintnum],
      ([newAccount, newval]) => {
        if (newAccount) {
          initData();
          getNFTS();
        }
        if (data.showMint) {
          if (!newval || newval < 1) {
            data.mintnum = 1;
          }
          if (data.mintnum > data.mintLimit - data.hasMint) {
            data.mintnum = data.mintLimit - data.hasMint;
          }
        }
      }
    );
    return {
      ...toRefs(data),
      modules: [EffectCoverflow, Autoplay],
      showDrop,
      changeNum,
      goMint,
      handlePreview,
      showVideo,
      newImageUrl,
      Web3,
    };
  },
});
</script>
<style scoped lang="scss">
@import "@/common/css/common.scss";
@keyframes mint-scale {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.85);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes show-des {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}
.contain {
  padding: 22.7rem 20.2rem 20.4rem 10.6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: $bg;
  width: 100%;
}
.swiper {
  // width: 80rem;
  transform: translateX(-15rem);
  flex: -1;
  :deep(.swiper-slide) {
    // width: auto; /*根据内容调整宽度*/
    // width: 45rem;
    // width: 45rem;
    // height: 45rem;
    opacity: 0;
    transition: all 0.1s;
  }
  :deep(.swiper-slide-visible) {
    opacity: 1;
    transition: all 0.1s;
  }
  .slideimg {
    // width: 38rem;
    // height: 38rem;
    width: 45rem;
    height: 45rem;
    border-radius: 2rem;
    overflow: hidden;
    background: $bg;

    img {
      opacity: 0.5;
      width: 100%;
      height: 100%;
      display: block;
    }
  }
  .swiper-slide-active {
    .slideimg {
      transform: translateX(1rem);
      img {
        opacity: 1;
        width: 100%;
        height: 100%;
        display: block;
      }
    }
  }
}
.rightInfo {
  .title {
    font-family: "RB";
    font-size: 8.5rem;
    color: $title2;
    line-height: 8.5rem;
  }
  .title2 {
    font-family: "RB";
    color: $title2;
    font-size: 12rem;
    line-height: 10.5rem;
  }
  .whiteTitle {
    font-family: "RB";
    font-size: 6.5rem;
    color: $title;
    display: inline-block;
    padding: 0.71rem 2.36rem;
    background: $main;
    border-radius: 4.8rem;
    margin-bottom: 2rem;
  }
  .publicTitle {
    width: 61.9rem;
    // height: 10.83rem;
    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
  .count {
    margin-top: -5rem;
    .info {
      font-size: 5rem;
      font-family: "RB";
      .line {
        // letter-spacing: -0.043rem;
        line-height: 4.1rem;
        border-bottom: 0.36rem solid $main;
      }
      .day {
        font-family: "DINCond";
        display: inline-block;
        transform: translateY(2.5rem);
        margin: 0 1.2rem;
        color: $title;
        font-size: 12rem;
      }
    }
    .times {
      font-family: "DINCond";
      font-size: 10.8rem;
      margin-top: 3.43rem;
      span {
        display: inline-block;
        background: $title;
        color: $sub;
        width: 14.3rem;
        height: 14.3rem;
        border-radius: 1.6rem;
        line-height: 14.3rem;
        vertical-align: middle;
        &:not(:first-child) {
          margin-left: 5.3rem;
          position: relative;
          &::before {
            content: "·";
            position: absolute;
            font-size: 9rem;
            color: $main;
            left: -4rem;
            line-height: 4rem;
          }
          &::after {
            content: "·";
            position: absolute;
            font-size: 9rem;
            color: $main;
            left: -4rem;
            line-height: 21rem;
          }
        }
      }
    }
  }
  .name {
    font-family: "RB";
    font-size: 3.9rem;
    line-height: 3rem;
    margin-top: 2.2rem;
  }
  .minted {
    font-size: 2.6rem;
    font-family: "RB";
    opacity: 0.5;
    // text-align: center;
    margin-top: 1.5rem;
    .num {
      color: $title2;
      margin-left: 1.4rem;
    }
  }
  .mint-btn {
    background: $title2;
    border-radius: 4rem;
    font-family: "RB";
    // width: 17.6rem;
    cursor: pointer;
    padding: 0 1.9rem;
    height: 6.1rem;
    margin-top: 7.1rem;
    span {
      color: $sub;
      opacity: 0.4;
      font-size: 4rem;
    }
    img {
      width: 3rem;
      margin-bottom: 1rem;
      margin-left: 0.9rem;
    }
    &.minted {
      span {
        font-size: 2.5rem;
      }
      opacity: 1;
      margin-right: 4.2rem;
    }
    &.gallery {
      span {
        font-size: 3.2rem;
      }
    }
    &.changeOpacity {
      // &:hover {
      //   img {
      //     animation: mint-scale 2s infinite;
      //   }
      // }
      transition: all 0.3s;
      &:active {
        transform: scale(0.85);
      }
    }
  }
}

.changeOpacity {
  cursor: pointer;
  span {
    opacity: 1 !important;
  }
}
.drop {
  .unknown-box {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: auto;
    .close {
      top: 19rem;
      right: 4rem;
      text-align: right;
      position: absolute;
      cursor: pointer;
      img {
        width: 4rem;
        height: 4rem;
        // display: block;
      }
    }
    .boxImg {
      width: 75vh;
      height: 75vh;
      position: relative;
      margin: 13vh auto 0;
      overflow: hidden;
      background: #000000;
      transition: opacity 0.5s;
      .blurimg {
        width: 100%;
        height: 100%;
        display: block;
      }
      .lock {
        position: absolute;
        top: 0;
        left: 0;
        width: 75vh;
        height: 75vh;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(0, 0, 0, 0.75);
        img {
          width: 20%;
          height: auto;
          display: block;
          cursor: pointer;
          animation: mint-scale 2s infinite;
        }
      }
      .openvideo {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        visibility: hidden;
        transition: all 0.3s;
      }
      .aniHidden {
        opacity: 1;
        position: relative;
        z-index: 1;
        transition: opacity 0.3s;
      }
    }
  }
  .card {
    background: $title;
    display: inline-block;
    border-radius: 2.5rem;
    padding: 0 1.7rem;
    top: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    &.nfts {
      padding: 0 2.9rem;
    }
    > .close {
      width: 3rem;
      height: 3rem;
      // position: relative;
      // margin-top: 2.2rem;
      position: absolute;
      z-index: 2022;
      align-self: flex-end;
      right: 3.4rem;
      top: 2.9rem;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
        display: block;
      }
    }
    .rightInfo {
      display: flex;
      flex-flow: column;
      flex: 1;
      overflow: hidden;
      // margin-top: 1.4rem;
      .title {
        font-size: 4rem;
        color: $sub;
        text-align: left;
        line-height: 4rem;
      }
      // min-width: 45rem;
      .close {
        width: 3rem;
        height: 3rem;
        position: relative;
        margin-top: 1rem;
        transform: translateY(1.2rem);
        align-self: flex-end;
        right: 0;
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
          display: block;
        }
      }
      .minted {
        font-size: 1.9rem;
        font-family: "RB";
        // text-align: center;
        opacity: 1;
        margin-top: 1.3rem;
        .num {
          opacity: 0.52;
          color: $sub;
          margin-left: 2rem;
        }
      }
      .topbox {
        width: 59rem;
        margin: 0 auto;
        border-radius: 1.7rem;
        overflow: hidden;
        margin-top: 1.4rem;
        position: relative;
        img {
          width: 100%;
          height: auto;
          display: block;
        }
        .waitingDrop {
          font-size: 5rem;
          color: $title;
          background: rgba(0, 0, 0, 0.9);
          width: 100%;
          height: 100%;
          position: absolute;
          z-index: 2001;
          top: 0;
          line-height: 59rem;
          span {
            animation: show-des 4s infinite;
          }
        }
      }
      .mid {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 2.6rem;
        .count {
          background: #e0e0e0;
          border-radius: 4.3rem;
          margin-right: 1.9rem;
          margin-top: 0;
          // width: fit-content;
          padding: 0.4rem 1rem;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          .num {
            color: $sub;
            font-size: 2.5rem;
            background: #f2f2f2;
            width: 6rem;
            padding: 0 0.5rem;
            height: 3.7rem;
            border-radius: 3.6rem;
            line-height: 3.5rem;
            display: inline-block;
            margin: 0 0.9rem;
            text-align: center;
          }
          button {
            cursor: pointer;
            img {
              width: 2.4rem;
              height: auto;
              vertical-align: middle;
            }
          }
        }
        .max {
          cursor: pointer;
          color: #ffffff;
          width: 17.7rem;
          height: 4.4rem;
          line-height: 4.4rem;
          background: linear-gradient(327deg, #7032d1 0%, #be95ff 100%);
          box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
          font-size: 3rem;
          font-family: "RB";
          border-radius: 5.3rem;
        }
      }
      .hasMax {
        font-size: 1.5rem;
        color: #ff3e3e;
        opacity: 0.6;
        margin-top: 2.3rem;
      }
      .btn {
        background: $bg;
        font-family: "RB";
        font-size: 3.5rem;
        // padding: 1.3rem 0 1.14rem;
        height: 6.6rem;
        color: $title;
        border-radius: 5.3rem;
        white-space: nowrap;
        margin: 1.9rem auto 4rem;
        width: 42rem;
        cursor: pointer;
        // display: inline-block;
        &:active {
          transform: scale(0.85);
        }
      }
      .nftlist {
        width: auto;
        display: flex;
        margin: 2.3rem 0.9rem 0;
        justify-content: space-between;
        // height: 38.5rem;
        overflow: auto;
        li {
          // height: 38.5rem;
          &:not(:first-child) {
            margin-left: 2.6rem;
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            display: block;
          }
          .nft {
            overflow: hidden;
            border-radius: 2rem;
            width: 38.5rem;
            height: 38.5rem;
            position: relative;
            z-index: 1;
            cursor: pointer;
            .more {
              position: absolute;
              z-index: 2;
              width: 100%;
              height: 100%;
              background: rgba(83, 83, 83, 0.74);
              font-family: RB;
              font-size: 5rem;
              line-height: 38.5rem;
              top: 0;
              color: $title;
            }
          }
          .nft-name {
            font-size: 1.8rem;
            color: #2e2e2e;
            margin: 1.4rem 0 1.8rem;
            font-family: "RB";
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 756px) {
  .ios {
    display: block;
  }
  .box {
    min-height: calc(100vh);
    overflow-x: hidden;
    background: $bg;
    .contain {
      padding: 7.5rem 0 9.5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: $bg;
      flex-flow: column;
    }
    .swiper {
      &.change {
        width: 130vw;
      }
      margin: 0 auto;
      transform: translateX(-4.8rem);
      :deep(.swiper-slide) {
        // width: auto; /*根据内容调整宽度*/
        // width: 45rem;
        opacity: 0;
      }
      :deep(.swiper-slide-visible) {
        opacity: 1;
        .slideimg {
          box-shadow: 0.2rem 0.2rem 1rem 0px #4a4e6932;
        }
      }
      .slideimg {
        width: 14.4rem;
        height: 14.4rem;
        border-radius: 1rem;
      }
      :deep(.swiper-slide-active) {
        .slideimg {
          transform: translateX(0.6rem);
        }
      }
      // :deep(.swiper-slide-duplicate-prev) {
      //   margin-left: -15rem;
      //   margin-right: 25rem;
      // }
    }
    .rightInfo {
      margin-top: 2.9rem;
      .whiteTitle {
        font-family: "RB";
        font-size: 3.5rem;
        color: $title;
        display: inline-block;
        padding: 0.71rem 2.36rem;
        background: $main;
        border-radius: 4.8rem;
        margin-bottom: 0;
      }
      .count {
        margin-top: -1rem;
        .info {
          font-size: 2.6rem;
          font-family: "RB";
          .line {
            // letter-spacing: -0.043rem;
            line-height: 4.1rem;
            border-bottom: 0.36rem solid $main;
          }
          .day {
            font-family: "DINCond";
            display: inline-block;
            transform: translateY(1.2rem);
            margin: 0 1.2rem;
            color: $title;
            font-size: 6.4rem;
          }
        }
        .times {
          font-family: "DINCond";
          font-size: 5.8rem;
          margin-top: 2.5rem;
          span {
            display: inline-block;
            background: $title;
            color: $sub;
            width: 7.7rem;
            height: 7.9rem;
            border-radius: 1.6rem;
            line-height: 7.9rem;
            vertical-align: middle;
            &:not(:first-child) {
              margin-left: 2.9rem;
              position: relative;
              &::before {
                content: "·";
                position: absolute;
                font-size: 5rem;
                color: $main;
                left: -2.2rem;
                line-height: 2rem;
              }
              &::after {
                content: "·";
                position: absolute;
                font-size: 5rem;
                color: $main;
                left: -2.2rem;
                line-height: 12rem;
              }
            }
          }
        }
      }
      .publicTitle {
        width: 28.9rem;
        // height: 10.83rem;
        img {
          display: block;
          width: 100%;
          height: auto;
        }
      }
      .title {
        font-size: 5.3rem;
        line-height: 5.3rem;
      }
      .title2 {
        font-size: 6.5rem;
        line-height: 6.5rem;
      }
      .name {
        margin-top: 1.1rem;
        font-size: 2.4rem;
        line-height: 1.9rem;
      }
      .minted {
        font-size: 1.5rem;
        margin-top: 2rem;
        .num {
          color: $title2;
          margin-left: 0.9rem;
        }
      }
      .mint-btn {
        border-radius: 4rem;
        // width: 17.6rem;
        padding: 0 1rem;
        height: 3.8rem;
        margin-top: 2rem;
        span {
          font-size: 2.6rem;
        }
        img {
          width: 1.8rem;
          margin-bottom: 0.4rem;
          margin-left: 0.3rem;
        }
        &.minted {
          span {
            font-size: 1.1rem;
          }
          opacity: 1;
          margin-right: 0.7rem;
        }
        &.gallery {
          span {
            font-size: 1.5rem;
          }
        }
        &.changeOpacity {
          // &:hover {
          //   img {
          //     animation: mint-scale 2s infinite;
          //   }
          // }
          transition: all 0.3s;
          &:active {
            transform: scale(0.85);
          }
        }
      }
    }

    .changeOpacity {
      cursor: pointer;
      span {
        opacity: 1 !important;
      }
    }
    .drop {
      max-height: 100vh;
      overflow-y: auto;
      .unknown-box {
        .close {
          top: 2rem;
          right: 2rem;
          text-align: right;
          position: absolute;
          cursor: pointer;
          img {
            width: 2.2rem;
            height: 2.2rem;
            // display: block;
          }
        }
        .boxImg {
          width: 65vw;
          height: 65vw;
          position: relative;
          overflow: hidden;
          margin: calc((100vh - 90vw) / 2) auto 0;
          background: #000000;
          transition: opacity 0.5s;
          .blurimg {
            width: 100%;
            height: 100%;
            display: block;
          }
          .lock {
            position: absolute;
            top: 0;
            left: 0;
            width: 65vw;
            height: 65vw;
            display: flex;
            align-items: center;
            justify-content: center;
            background: rgba(0, 0, 0, 0.75);
            img {
              width: 20%;
              height: auto;
              display: block;
              cursor: pointer;
              animation: mint-scale 2s infinite;
            }
          }
          .openvideo {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            visibility: hidden;
            transition: all 0.3s;
          }
        }
      }
      .card {
        background: $title;
        display: inline-block;
        border-radius: 1.6rem;
        padding: 0 0.8rem;

        top: 50%;
        &.nfts {
          top: 10%;
          transform: translate(-50%, 50%);
          margin-bottom: 18rem;
          padding: 0 1.2rem;
        }
        > .close {
          width: 1.4rem;
          height: 1.4rem;
          position: absolute;
          margin-top: 1.6rem;
          align-self: flex-end;
          top: 0rem;
          right: 2rem;
          cursor: pointer;
          img {
            content: url("../assets/mint/close_mini.png");
          }
        }
        .rightInfo {
          display: flex;
          flex-flow: column;
          flex: 1;
          overflow: hidden;
          margin: 0;
          .title {
            // display: none;
            font-size: 2rem;
            line-height: 1.8rem;
          }
          // min-width: 45rem;
          .close {
            width: 1.4rem;
            height: 1.4rem;
            position: relative;
            margin-top: 1.3rem;
            align-self: flex-end;
            right: 0;
            transform: translateY(0);
            cursor: pointer;
            img {
              content: url("../assets/mint/close_mini.png");
            }
          }
          .minted {
            font-size: 0.9rem;
            font-family: "RB";
            // text-align: center;
            opacity: 1;
            margin-top: 0.5rem;
            .num {
              opacity: 0.52;
              color: $sub;
              margin-left: 0.228rem;
            }
          }
          .topbox {
            width: 28.2rem;
            margin: 0.7rem auto 0;
            img {
              width: 100%;
              height: auto;
              display: block;
            }
            .waitingDrop {
              font-size: 3rem;
              color: $title;
              background: rgba(0, 0, 0, 0.9);
              width: 100%;
              height: 100%;
              position: absolute;
              z-index: 2001;
              top: 0;
              line-height: 28.2rem;
              span {
                animation: show-des 4s infinite;
              }
            }
          }
          .mid {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 1.3rem;
            .count {
              background: #e0e0e0;
              border-radius: 0.43rem;
              margin-right: 0.9rem;
              // width: 6.6rem;
              padding: 0.4rem 0.6rem;
              // width: fit-content;
              border-radius: 4.3rem;
              .num {
                color: $sub;
                font-size: 1.2rem;
                background: #f2f2f2;
                // min-width: 1.6rem;
                width: 2.8rem;
                padding: 0;
                height: 1.6rem;
                border-radius: 3.6rem;
                line-height: 1.6rem;
                display: inline-block;
                transform: translateY(0);
                margin: 0 0.5rem;
              }
              button {
                // height: 2.1rem;
                // line-height: 2rem;
                // padding: 0 2rem;
                cursor: pointer;
                img {
                  width: 1.2rem;
                  height: auto;
                  vertical-align: middle;
                }
              }
            }
            .max {
              width: 8.4rem;
              height: 2.1rem;
              line-height: 2.1rem;
              font-size: 1.4rem;
            }
          }
          .btn {
            background: $bg;
            font-family: "RB";
            font-size: 1.6rem;
            height: 3.1rem;
            color: $title;
            border-radius: 5.3rem;
            white-space: nowrap;
            margin: 1.1rem auto 3.1rem;
            cursor: pointer;
            width: 20rem;
            &:active {
              transform: scale(0.85);
            }
          }
          .nftlist {
            display: flex;
            margin: 1.1rem 0 1.2rem;
            justify-content: left;
            grid-template-columns: 1fr; /*设置等比列*/
            gap: 4.25rem; /*行间距*/
            overflow: hidden;
            li {
              float: left;
              width: 23.4rem;
              // height: 23.4rem;
              border-radius: 1.5rem;
              overflow: hidden;
              margin-right: 0;
              .nft {
                width: 23.4rem;
                height: 23.4rem;
                .more {
                  display: block !important;
                  font-size: 3rem;
                  line-height: 23.4rem;
                }
              }
              .nft-name {
                margin-bottom: 0;
                margin-top: 1rem;
                font-size: 1rem;
                font-family: "RB";
              }
              &:not(:nth-child(1)) {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}
</style>
