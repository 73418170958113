<template>
  <div class="my_bottom" :class="{ bindbg: $route.path === '/team' }">
    <div class="roll">
      <div class="loop">
        <span class="line1">DESTROY<span class="line">AND</span>REBUILD </span>
        <span class="line1">DESTROY<span class="line">AND</span>REBUILD </span>
        <span class="pc">DESTROY<span class="line">AND</span>REBUILD </span>
      </div>
    </div>
    <p class="info pc">
      2022 Destroy and... <span class="thin">All rights reserved.</span>
    </p>
    <ul class="linkList" v-if="$route.path !== '/team'">
      <li>
        <a href="https://opensea.io/collection/destroyand" target="_blank">
          <img src="@/assets/link/opensea_dark.png" alt="" />
        </a>
      </li>
      <li>
        <a href="https://twitter.com/destroyand_" target="_blank">
          <img src="@/assets/link/twitter_dark.png" alt="" />
        </a>
      </li>
      <li>
        <a href="https://discord.com/invite/destroyand" target="_blank">
          <img src="@/assets/link/discord_dark.png" alt="" />
        </a>
      </li>
      <li>
        <a href="https://www.instagram.com/destroyand_" target="_blank">
          <img src="@/assets/link/ins_dark.png" alt="" />
        </a>
      </li>
    </ul>
    <ul class="linkList team" v-else>
      <li>
        <a href="https://opensea.io/collection/destroyand" target="_blank">
          <img src="@/assets/link/opensea.png" alt="" />
        </a>
      </li>
      <li>
        <a href="https://twitter.com/destroyand_" target="_blank">
          <img src="@/assets/link/twitter.png" alt="" />
        </a>
      </li>
      <li>
        <a href="https://discord.com/invite/destroyand" target="_blank">
          <img src="@/assets/link/discord.png" alt="" />
        </a>
      </li>
      <li>
        <a href="https://www.instagram.com/destroyand_" target="_blank">
          <img src="@/assets/link/ins.png" alt="" />
        </a>
      </li>
    </ul>
    <picture>
      <source
        media="(max-width: 756px)"
        srcset="@/assets/mobile_gif/line.gif"
      />
      <img class="gif_line" src="@/assets/gif/line.gif" alt="" />
    </picture>
    <p class="info mobile">
      2022 Destroy and... <span class="thin">All rights reserved.</span>
    </p>
  </div>
</template>
<script>
import { defineComponent, reactive, toRefs } from "vue";
export default defineComponent({
  setup() {
    const data = reactive({});
    return {
      ...toRefs(data),
    };
  },
});
</script>
<style scoped lang="scss">
@import "@/common/css/common.scss";
@keyframes loop {
  0% {
    transform: translateX(3.9rem);
    // -webkit-transform: translateX(40vw);
  }
  100% {
    transform: translateX(-113rem);
    // -webkit-transform: translateX(-40vw);
  }
}
.my_bottom {
  position: relative;
  z-index: 5;
  text-align: center;
  .info {
    color: $title;
    font-size: 2.9rem;
    .thin {
      color: $title;
      font-size: 2.9rem;
      font-family: "PF_light";
    }
  }
  .linkList {
    display: inline-flex;
    align-items: center;
    margin-top: 3.6rem;
    position: relative;
    z-index: 2;
    li {
      margin: 0 1.6rem;
      img {
        width: 4.4rem;
        height: auto;
        display: block;
      }
      &:nth-child(1):hover {
        img {
          content: url("../assets/link/opensea.png");
        }
      }
      &:nth-child(2):hover {
        img {
          content: url("../assets/link/twitter.png");
        }
      }
      &:nth-child(3):hover {
        img {
          content: url("../assets/link/discord.png");
        }
      }
      &:nth-child(4):hover {
        img {
          content: url("../assets/link/ins.png");
        }
      }
    }
    &.team {
      li {
        &:nth-child(1):hover {
          img {
            content: url("../assets/link/opensea_dark.png");
          }
        }
        &:nth-child(2):hover,
        &:nth-child(2):active {
          img {
            content: url("../assets/link/twitter_dark.png");
          }
        }
        &:nth-child(3):hover {
          img {
            content: url("../assets/link/discord_dark.png");
          }
        }
        &:nth-child(4):hover {
          img {
            content: url("../assets/link/ins_dark.png");
          }
        }
      }
    }
  }
  .roll {
    background: $main;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    margin-bottom: 8.7rem;
    padding-bottom: 0.4rem;

    .loop {
      animation: loop linear 8s infinite;
      span {
        font-size: 8.5rem;
        color: $sub;
        // height: 10rem;
        // line-height: 10rem;
        font-family: "DTL";
        &.line {
          color: $main;
          // line-height: 90px;
          -webkit-text-stroke: 0.3rem $sub;
          text-stroke: 0.3rem $sub;
          margin: 0 3.5rem;
        }
      }
      .line1 {
        margin-right: 4.9rem;
      }
    }
  }
  &.bindbg {
    .roll {
      background: $sub;
      .loop {
        animation: loop linear 8s infinite;
        span {
          color: $main;
          &.line {
            color: $sub;
            -webkit-text-stroke: 0.3rem $main;
            text-stroke: 0.3rem $main;
          }
        }
        .line1 {
          margin-right: 4.9rem;
        }
      }
    }
  }
  .gif_line {
    width: 28.7rem;
    height: auto;
    display: block;
    margin: -10.5rem auto 0;
  }
  .mobile {
    display: none;
  }
}
@media only screen and (max-width: 765px) {
  @keyframes loop {
    0% {
      transform: translateX(3.7rem);
      // -webkit-transform: translateX(40vw);
    }
    100% {
      transform: translateX(-36rem);
      // -webkit-transform: translateX(-40vw);
    }
  }
  .my_bottom {
    padding-bottom: 4rem;
    .mobile {
      display: block;
    }
    position: relative;
    z-index: 5;
    text-align: center;
    .pc {
      display: none;
    }
    .info {
      color: $title;
      font-size: 1.2rem;
      margin-top: -4.4rem;
      .thin {
        color: $title;
        font-size: 1.2rem;
        font-family: "PF_bold";
      }
    }
    .linkList {
      display: inline-flex;
      align-items: center;
      margin-top: 4.6rem;
      position: relative;
      z-index: 2;
      li {
        margin: 0 0.9rem;
        img {
          width: 2.9rem;
          height: auto;
          display: block;
        }
        &:nth-child(1):active {
          img {
            content: url("../assets/link/opensea.png");
          }
        }
        &:nth-child(2):active {
          img {
            content: url("../assets/link/twitter.png");
          }
        }
        &:nth-child(3):active {
          img {
            content: url("../assets/link/discord.png");
          }
        }
        &:nth-child(4):active {
          img {
            content: url("../assets/link/ins.png");
          }
        }
      }
    }
    .roll {
      background: $main;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      margin-bottom: 1.74rem;
      .loop {
        animation: loop linear 8s infinite;
        span {
          font-size: 2.8rem;
          color: $sub;
          height: 4.6rem;
          line-height: 4.6rem;
          font-family: "DTL";
          &.line {
            color: $main;
            // line-height: 10rem;
            -webkit-text-stroke: 0.1rem $sub;
            text-stroke: 0.1rem $sub;
            margin: 0 0.8rem;
          }
        }
        .line1 {
          margin-right: 3.4rem;
        }
      }
    }
    &.bindbg {
      .roll {
        background: $sub;
        .loop {
          animation: loop linear 8s infinite;
          span {
            color: $main;
            &.line {
              color: $sub;
              -webkit-text-stroke: 0.1rem $main;
              text-stroke: 0.1rem $main;
            }
          }
        }
      }
    }
    .gif_line {
      width: 19.2rem;
      height: auto;
      display: block;
      margin: -7.5rem auto 0;
      content: url("../assets/mobile_gif/line.gif");
    }
  }
}
</style>
