<template>
  <div class="page-ani">
    <div class="teammid">
      <ul class="teamList team1">
        <li>
          <img src="@/assets/team/p1.png" alt="" />
          <div class="right">
            <span class="name">Egrill </span>
            <span class="info">Founder</span>
            <ul>
              <li>- Entrepreneur | NFT DEGEN| Die in Web3</li>
              <li>- Partner of top token fund</li>
              <li>- Cofounder of 1.5B-TVL Defi project</li>
            </ul>
          </div>
        </li>

        <li>
          <img src="@/assets/team/p2.png" alt="" />
          <div class="right">
            <span class="name">Totallynotsus </span>
            <span class="info">Artist</span>
            <ul>
              <li>- 2D Animator I Illustrator</li>
              <li>
                - First Class Hons from the Best 2D Animation <br />
                school in the UK (2nd in the world)
              </li>
              <li>
                - Hoarded over 40 International Design and<br />
                Animation awards
              </li>
              <li>- Collaborated with Meta, United Nations</li>
            </ul>
          </div>
        </li>
      </ul>
      <ul class="teamList team2">
        <li>
          <img src="@/assets/team/p3.png" alt="" />
          <div class="right">
            <span class="name">ff15 </span>
            <span class="info">Community </span>
            <ul>
              <li>- Blockchain OG | Deﬁ Farmer | NFT DEGEN</li>
              <li>
                - Cofounded one of the earliest decentralized<br />
                exchanges in 2018
              </li>
              <li>- EX Top 3 crypto exchange</li>
            </ul>
          </div>
        </li>
        <li>
          <img src="@/assets/team/p4.png" alt="" />
          <div class="right">
            <span class="name">Identity X </span>
            <span class="info">Product</span>
            <ul>
              <li>- Game Designer | Gameﬁ Researcher</li>
              <li>- Cornell Information Science</li>
              <li>- Expert in Token Economics | Gamiﬁcation</li>
              <li>- Ex Top 10 Gaming Company</li>
            </ul>
          </div>
        </li>
        <li>
          <img src="@/assets/team/p5.png" alt="" />
          <div class="right">
            <span class="name">Simp </span>
            <span class="info">Developer</span>
            <ul>
              <li>- Blockchain OG | Serial entrepreneur</li>
              <li>- Expert in Smart Contract</li>
              <li>- Experienced in system security</li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
    <div class="ios bg">
      <img src="@/assets/imgs/mobile_bg.png" alt="" />
      <img src="@/assets/imgs/mobile_bg.png" alt="" />
      <img src="@/assets/imgs/mobile_bg.png" alt="" />
      <img src="@/assets/imgs/mobile_bg.png" alt="" />
      <img src="@/assets/imgs/mobile_bg.png" alt="" />
    </div>
  </div>
</template>
<script>
import { defineComponent, reactive, toRefs } from "vue";
// import Bottom from "../components/Bottom.vue";
import useClipboard from "vue-clipboard3";
export default defineComponent({
  components: {
    // Bottom,
  },
  setup() {
    const data = reactive({
      show: false,
    });
    const { toClipboard } = useClipboard();

    const copy = async (text) => {
      try {
        await toClipboard(text);
        console.log("Copied to clipboard");
      } catch (e) {
        console.error(e);
      }
    };
    return {
      ...toRefs(data),
      copy,
    };
  },
});
</script>
<style scoped lang="scss">
@import "@/common/css/common.scss";
.page-ani {
  background: $main;
  height: calc(100vh - 15.7rem);
  min-height: 550px;
  position: relative;
  overflow-x: hidden;
}
.ios {
  display: none;
}
.teammid {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}
.teamList {
  display: flex;
  justify-content: center;
  align-items: baseline;
  // padding-top: 15.2rem;
  // padding-bottom: 20.8rem;
  width: 100vw;
  > li {
    display: flex;
    // flex-flow: column;
    align-items: center;
    display: flex;
    text-align: left;
    img {
      display: block;
      height: auto;
      margin-right: 3.6rem;
    }
    .right {
      display: flex;
      flex-direction: column;
    }
    .name {
      font-size: 5.2rem;
      // margin-top: 7.3rem;
      color: #ffea06;
      font-family: "RB";
    }
    .info {
      // margin-top: 2.4rem;
      font-size: 3.1rem;
      color: #898989;
      font-family: "RB";
    }
    ul {
      margin-top: 3rem;
      > li {
        color: $title;
      }
    }
  }
  &.team1 {
    > li {
      &:nth-child(1) {
        margin-right: 17.1rem;
        img {
          width: 17.6rem;
        }
      }
      &:nth-child(2) {
        .name {
          font-size: 3.4rem;
          // margin-top: 10.3rem;
        }
        .info {
          // margin-top: 0.6rem;
          font-size: 3.1rem;
        }
        img {
          width: 19rem;
        }
      }
    }
  }
  &.team2 {
    padding: 10rem 5rem 7rem;

    > li {
      &:nth-child(1) {
        margin-right: 10.6rem;
        img {
          width: 19.3rem;
        }
        li {
          // white-space: nowrap;
          max-width: 31rem;
        }
      }
      &:nth-child(2) {
        margin-right: 11.4rem;
        img {
          width: 23.5rem;
        }
      }
      &:nth-child(3) {
        img {
          width: 18.3rem;
        }
      }
    }
  }
  &.ios {
    display: none;
  }
}
.title1 {
  font-family: "DTL";
}

@media only screen and (max-width: 765px) {
  .page-ani {
    position: relative;
    z-index: 0;
    height: auto;
    min-height: 100vh;
    // opacity: 1;
  }
  .title1 {
    text-align: center;
    font-size: 3rem;
    letter-spacing: 0.04rem;
    width: auto;
    // font-family: "DTL";
    // margin: 0 auto;
  }
  .teammid {
    position: relative;
    top: 0;
    left: 0;
    transform: translate(0, 0);
    z-index: 2;
  }
  .ios {
    display: block;
    &.bg {
      display: flex;
      flex-direction: column;
      position: fixed;
      top: 13rem;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100vh;
      justify-content: center;
      // background: white;
      img {
        // position: relative;
        // opacity: 1;
        z-index: 2;
        display: block;
        width: 35.8rem;
        height: 12.2rem;
        // background: pink;
        margin: 0 auto;
        // content: url("../assets/imgs/mobile_bg.png");
        margin-bottom: 3rem;
      }
    }
  }
  .teamList {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: baseline;
    padding-top: 0;
    position: relative;
    z-index: 2;
    // padding-bottom: 20.8rem;

    > li {
      display: flex;
      // flex-flow: column;
      align-items: center;
      display: flex;
      text-align: left;
      margin: 0 auto 5.5rem;
      img {
        display: block;
        height: auto;
        margin-right: 2rem;
      }
      .right {
        display: flex;
        flex-direction: column;
      }
      .name {
        font-size: 3.4rem;
        // margin-top: 7.3rem;
        color: #ffea06;
        font-family: "RB";
      }
      .info {
        font-size: 2rem;
        // margin-top: 2.4rem;
        color: #898989;
        font-family: "RB";
      }
      ul {
        margin-top: 0.5rem;
        > li {
          color: $title;
          font-size: 0.8rem;
        }
      }
    }
    &.team1 {
      padding-top: 10rem;
      > li {
        &:nth-child(1) {
          margin-right: auto;
          img {
            width: 11.4rem;
          }
        }
        &:nth-child(2) {
          flex-direction: row-reverse;
          text-align: right;
          .name {
            font-size: 2.2rem;
            // margin-top: 10.3rem;
          }
          .info {
            // margin-top: 0.6rem;
            font-size: 2.2rem;
          }
          img {
            width: 12rem;
            margin-left: 2rem;
            margin-right: 0;
          }
        }
      }
    }
    &.team2 {
      padding: 0;
      > li {
        &:nth-child(1) {
          margin-right: auto;
          img {
            width: 12rem;
          }
        }
        &:nth-child(2) {
          margin-right: auto;
          flex-direction: row-reverse;
          text-align: right;
          img {
            width: 15rem;
            margin-left: 2rem;
          }
        }
        &:nth-child(3) {
          img {
            width: 10.9rem;
          }
        }
      }
    }
  }
}
</style>
