<template>
  <div class="mint">
    <comingPage v-if="iscoming" />
    <myMint v-else />
  </div>
</template>
<script>
import { defineComponent, reactive, toRefs } from "vue";
import myMint from "../components/mintMid.vue";
import comingPage from "../components/coming.vue";
export default defineComponent({
  components: {
    myMint,
    comingPage,
  },
  setup() {
    const data = reactive({
      iscoming: false,
    });
    return {
      ...toRefs(data),
    };
  },
});
</script>
<style scoped lang="scss">
.mint {
  position: relative;
  // height: 100vh;
  // background: 100%;
  background-size: 100%;
  overflow: auto;
  user-select: none;
}
</style>
