<template>
  <div class="videobox">
    <video
      muted
      :autoplay="false"
      id="myvideo"
      class="myvideo"
      preload
      :webkit-playsinline="true"
      :playsinline="true"
      x5-video-player-type="h5"
      :src="require(`@/assets/video/p${statusNow}.mp4`)"
    >
      <source
        :src="require(`@/assets/video/p${statusNow}.mp4`)"
        type="video/mp4"
      />
    </video>
    <video
      muted
      loop="loop"
      :autoplay="false"
      id="myvideo2"
      class="myvideo"
      preload
      :webkit-playsinline="true"
      :playsinline="true"
      x5-video-player-type="h5"
      :src="require(`@/assets/video/p2.mp4`)"
    >
      <source :src="require(`@/assets/video/p2.mp4`)" type="video/mp4" />
    </video>
    <video
      muted
      :autoplay="true"
      id="myvideo3"
      class="myvideo"
      preload
      :webkit-playsinline="true"
      :playsinline="true"
      x5-video-player-type="h5"
      :src="require(`@/assets/video/p3.mp4`)"
    >
      <source :src="require(`@/assets/video/p3.mp4`)" type="video/mp4" />
    </video>
    <!-- <img class="minted" id="mintedImg" src="@/assets/mint/minted.png" alt="" /> -->
  </div>
</template>
<script>
import {
  defineComponent,
  reactive,
  toRefs,
  onMounted,
  // nextTick,
  watch,
} from "vue";

export default defineComponent({
  props: {
    status: {
      type: [String],
    },
  },
  setup(props) {
    const data = reactive({
      statusNow: "1",
    });
    onMounted(() => {
      playVideo();
    });
    const playVideo = () => {
      var startPlay = true;
      var vplayer_start = document.getElementById("myvideo");
      var vplayer = document.getElementById("myvideo2");

      vplayer_start.pause();
      vplayer.play();
      // 播放结束
      vplayer_start.addEventListener("ended", function (e) {
        vplayer.style.visibility = "visible";
        vplayer.play();
        e.target.style.display = "none";
      });
      //循环播放第二个视频
      vplayer.addEventListener("ended", function (e) {
        e.target.play();
      });

      vplayer.addEventListener("canplay", function (e) {
        if (startPlay) {
          e.target.pause();
          vplayer_start.style.visibility = "visible";
          vplayer_start.play();
          startPlay = false;
        }
      });
    };
    const playVideoend = () => {
      // let startPlay = true;
      var vplayer_start = document.getElementById("myvideo2");
      var vplayer = document.getElementById("myvideo3");
      // const mintimg = document.getElementById("mintedImg");
      // mintimg.style.visibility = "hidden";
      vplayer_start.style.display = "none";
      vplayer.style.visibility = "visible";
      vplayer.play();
      // 播放结束
      //循环播放第三个视频
      vplayer.addEventListener("ended", function (e) {
        // mintimg.style.visibility = "visible";
        e.target.pause();
        vplayer_start.style.visibility = "hidden";
      });

      // vplayer.addEventListener("canplay", function (e) {
      //   if (startPlay) {
      //     e.target.pause();
      //     vplayer_start.style.visibility = "visible";
      //     vplayer_start.play();
      //     startPlay = false;
      //   }
      // });
    };
    watch(
      () => props.status,
      (newVal) => {
        if (newVal) {
          data.statusNow = newVal;
          console.log(newVal);

          if (data.statusNow === "3") {
            console.log("进来了");
            playVideoend();
          }
        }
      }
    );
    return {
      ...toRefs(data),
    };
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.videobox {
  // min-height: 100vh;
  position: relative;
  z-index: 2000;
  width: 100%;
  height: 59rem;
  // background: #000;
  #myvideo2 {
    position: absolute;
    left: 0;
    top: 0;
    visibility: hidden;
  }
  #myvideo3 {
    position: absolute;
    left: 0;
    top: 0;
    visibility: hidden;
  }
  .minted {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    visibility: hidden;
  }
  .myvideo {
    width: 100%;
    display: block;
    // position: absolute;
    // top: 50%;
    // left: 50%;
    // transform: translateY(-50%) translateX(-50%);
    // background: red;
    // width: 963px;
  }
  img {
    display: none;
  }
}
@media only screen and (max-width: 765px) {
  .videobox {
    // min-height: 100vh;
    position: relative;
    z-index: 2000;
    width: 100%;
    height: 28.2rem;
    // background: #000;
  }
}
</style>
